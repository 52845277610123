import { environment } from 'src/environments/environment';
import { Component, ContentChildren, ElementRef, Input, OnInit, Output, QueryList, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { AppData } from 'src/app/models/app-data';
import { ModalController } from '@ionic/angular';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';

/**
 * ID: bh-card
 * Name: BH Card
 * Description: A multi-functional data item that presents in standard card, list item, compact hero card, and metric
 * Version: 5
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - MW - v1: Initial dev
 * 2021-06-28 - MW - v2: Fixed subtitle date format
 * 2021-06-28 - MW - v3: Reduced strength of list item card-title font weight
 * 2021-07-23 - MW - v4: Added metric icon option and args
 * 2022-05-09 - MW - v5: Standardized attributes across cards, renamed from bh-content-card to bh-card
 */
@Component({
  selector: 'bh-card',
  templateUrl: './bh-card.component.html',
  styleUrls: ['./bh-card.component.scss'],
})
export class BhCardComponent implements OnInit {
  @Input() layout: 'standard' | 'hero' | 'list' | 'metric' | 'app' = 'standard';
  @Input() title: string = null;
  @Input() subtitle: string = null;
  @Input() ionIcon: string = null;
  @Input() ionIconColor = 'primary';
  @Input() showIconBadge = false;
  @Input() views = 0;
  @Input() likes = 0;
  @Input() comments = 0;
  @Input() isFavorite = false;
  @Input() imageUrl: string = null;
  @Input() showSocialToolbar = false;
  @Input() maxWidth = 'none';
  @Input() metricValue = null;
  @Input() metricDetail: string;
  @Input() clickArgument: any = null;
  @Input() appTitle: string = null;
  @Input() appDescription: string = null;
  @Input() selectedApp: AppData;
  @Output() clickEvent = new EventEmitter<any>();
  @ContentChildren('fullContent') standardContent: QueryList<ElementRef>;
  @ContentChildren('listContent') listContent: QueryList<ElementRef>;
  env = environment;
  heroImageUrl = '';

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
  ) { }

  ngOnInit() {
    this.setHeroImage();
    this.setSubtitleDate();
  }

  setHeroImage() {
    if (this.imageUrl) {
      if (this.imageUrl.indexOf('https:') === 0 || this.imageUrl.indexOf('https:') === 0) {
        this.heroImageUrl = this.imageUrl;
      } else if (this.imageUrl.toLowerCase().indexOf('filerepo:')) {
        this.heroImageUrl = this.imageUrl.toLowerCase().replace('filerepo:', this.env.filesUrl + '/').trim();
      } else {
        this.heroImageUrl = this.imageUrl;
      }
    }
  }

  setSubtitleDate() {
    const subtitleMoment = moment(this.subtitle, 'M/D/YYYY HH:mm');
    if (subtitleMoment.isValid()) {
      this.subtitle = subtitleMoment.format('MMM D, YYYY');
    }
  }

  click() {
    this.clickEvent.emit(this.clickArgument);
  }


  // async editApp(app: AppData, ev) {
  //   ev.stopPropagation();
  //   this.analytics.clickEvent('App > Edit', app.name);
  //   const modal = await this.modalCtrl.create({
  //     component: ManageAppEditorPage,
  //     cssClass: 'wide-modal',
  //     componentProps: {
  //       editMode: 'edit',
  //       appData: app,

  //     }
  //   });

  //   modal.onDidDismiss().then((data) => {
  //   });

  //   await modal.present();

  // }

}
