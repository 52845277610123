import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, HostBinding  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * ID: bh-validation-error
 * Name: BH Form Control Validation Error
 * Description: Validation error message for bh-input and Angular Reactive Form Inputs
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Component({
  selector: 'bh-validation-error',
  templateUrl: './bh-validation-error.component.html',
  styleUrls: ['./bh-validation-error.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BhValidationErrorComponent),
      multi: true
    }
  ],

})
export class BhValidationErrorComponent implements ControlValueAccessor {
  @Input() validationMessages: any;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() submitAttempted: boolean;
  @Input() padding: string;

  constructor() { }

  registerOnChange(fn) {
    // this.onChange = fn;
  }

  writeValue(value) {
    // if (value) {
      // this.value = value;
    // }
  }

  registerOnTouched(fn) {
    // this.onTouched = fn;
  }

}
