<ion-content>
  <ion-list lines="none">
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('my-account')">
      <ion-icon name="person-circle-outline" slot="start"></ion-icon>
      <ion-label>
        <h2>My Account</h2>
      </ion-label>
    </ion-item>
    <!-- <ion-item ion-button href='javascript:void(0)' (click)="openPage('/tabs/favorites')">
      <ion-icon name="star-outline" slot="start"></ion-icon>
      <ion-label>
        <h2>My Favorites</h2>
      </ion-label>
    </ion-item> -->
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('help')">
      <ion-icon name="help-circle-outline" slot="start"></ion-icon>
      <ion-label>
        <h2>Get Help</h2>
      </ion-label>
    </ion-item>
    <!-- <ion-item ion-button href='javascript:void(0)' (click)="openPage('about')">
      <ion-label>
        <h2>About {{ env.appDescription }}</h2>
      </ion-label>
    </ion-item> -->
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('feedback')">
      <ion-icon name="volume-medium-outline" slot="start"></ion-icon>
      <ion-label>
        <h2>Give feedback</h2>
      </ion-label>
    </ion-item>
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('share')">
      <ion-icon name="share-outline" slot="start"></ion-icon>
      <ion-label>
        <h2>Share this app</h2>
      </ion-label>
    </ion-item>
    <ion-item ion-button href='javascript:void(0)' (click)="openPage('logout')">
      <ion-icon name="log-out-outline" slot="start"></ion-icon>
      <ion-label>
        <h2>Sign out</h2>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
