import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { AppData } from 'src/app/models/app-data';
@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  env = environment;
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
    ) { }


  loadAccessData(appId: string) {
    const url = `${environment.apiUrl}/apps/${appId}/access`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'app-data-service.loadAccessData(): ' + url
        );
        return of(err);
      })
    );
  }

  updateAccessData(selectedApp: AppData) {
    const url = `${environment.apiUrl}/saveAppAccess`;
    const body = selectedApp;
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'faq-data--service.saveFAQs()' + url
        );
        return of(err);
      })
    );
  }

  getAllApps() {
    const url = `${environment.apiUrl}/apps`;
    return this.http.get(url).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'app-data-service.getAllApps(): ' + url
        );
        return of(err);
      })
    );
  }

  getSingleApp(appId) {
    const url = `${environment.apiUrl}/apps?app=${appId}`;
    //const body = JSON.parse('{"app": "APPSTORE"}');
    return this.http.get(url).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'app-data-service.getAllApps(): ' + url
        );
        return of(err);
      })
    );
  }

  getSingleAppByName(name: string) {
    const url = `${environment.apiUrl}/apps/${name}`;
    return this.http.get(url).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'app-data-service.getAllApps(): ' + url
        );
        return of(err);
      })
    );
  }

  filterApp(app: any, appId: string) {
    let matches = false;
    if (app.APP === appId) {
      matches = true;
    };
    return matches;
  }

  filterApps(app: any, queryWords: any) {
    let matchesQueryText = false;
    if (queryWords.length) {
      // of any query word is in the session name than it passes the query test
      queryWords.forEach((queryWord: string) => {
        if (app.status === 'A' || app.status === 'D' ) {  //Show only active or In-Development apps
          if (app.app.toUpperCase() ===  queryWord) {
              matchesQueryText = true;
          } else if (app.name.toLowerCase().indexOf(queryWord) > -1 ||
              app.description.toLowerCase().indexOf(queryWord) > -1 ||
              app.apptype.toLowerCase().indexOf(queryWord) > -1 ||
              app.searchtags.toLowerCase().indexOf(queryWord) > -1 ||
              this.checkCategory(app.filter_categories, queryWord)) {
            matchesQueryText = true;
          }
        }
      }
      );
    } else {
      // if there are no query words then this session passes the query test
      matchesQueryText = true;
    }

    return matchesQueryText;
  }

  checkCategory(categories: string, queryWord: string) {
    let found = false;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for(let i = 0; i < categories.length; i++) {
      if (categories[i].includes(queryWord)) {
        found = true;
        break;
      }
    }
    return found;
  }

  createApp(app: AppData) {
    const url = `${environment.apiUrl}/saveapp`;
    //const body = JSON.parse('{"app": "APPSTORE"}');
    const body = app;
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'app-data-service.getAllApps(): ' + url
        );
        return of(err);
      })
    );
  }

  updateApp(app: AppData) {
    const url = `${environment.apiUrl}/saveapp`;
    //const body = JSON.parse('{"app": "APPSTORE"}');
    const body = app;
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'app-data-service.getAllApps(): ' + url
        );
        return of(err);
      })
    );
  }


  // loadAccessData(appid: string) {
  //   //console.log("in loadAccessData");
  //   let url = this.CONFIG.ENDPOINTS.HOST
  //     + this.CONFIG.ENDPOINTS.APPS;
  //   url = this.CONFIG.ENV === 'local' ? 'assets/data/access.json' : url + '/' + appid + '/access';
  //   url = `${environment.apiUrl}/access`;
  //   console.log("URL:", url);
  //   return this.http.get(url)
  //     .map((res) => {
  //       let data = res.json().apps;
  //       return data;
  //     });
  // }


  // filterApps(app: any, queryWords: any) {
  //   let matchesQueryText = false;
  //   if (queryWords.length) {
  //     // of any query word is in the session name than it passes the query test
  //     queryWords.forEach((queryWord: string) => {
  //       if (app.status === 'A' || app.status === 'D' ) {  //Show only active or In-Development apps
  //         if (app.app.toUpperCase() ===  queryWord) {
  //             matchesQueryText = true;
  //         } else if (app.name.toLowerCase().indexOf(queryWord) > -1 ||
  //             app.description.toLowerCase().indexOf(queryWord) > -1 ||
  //             app.apptype.toLowerCase().indexOf(queryWord) > -1 ||
  //             app.searchtags.toLowerCase().indexOf(queryWord) > -1 ||
  //             this.checkCategory(app.filter_categories, queryWord)) {
  //           matchesQueryText = true;
  //         }
  //       }
  //     }
  //     );
  //   } else {
  //     // if there are no query words then this session passes the query test
  //     matchesQueryText = true;
  //   }

  //   return matchesQueryText;
  // }

  // ENDPOINTS: {
  //   HOST: "",
  //   LOGIN: "/login",
  //   APPS: "/apps",
  //   CATEGORIES: "/categories",
  //   NOTIFICATIONS: "/notifications",
  //   CONTACTUS: "/contactus",
  //   PROTECTED: "/protected",
  //   FAQ: "/faqs",
  //   ROBOCHECK: "/robocheck"
  // }


}
