<table mat-table matSort [dataSource]="dataSource" class="clickable mat-elevation-z8" (matSortChange)="sortChanged($event)">
  <ng-container
    *ngFor="let cd of columnDefinitions"
    [matColumnDef]="cd.fieldName"
  >
    <ng-container *ngIf="cd.showColumn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{'background-color': 'var(--branding-blue)'}">
        {{ cd.columnLabel }}
      </th>
      <td mat-cell *matCellDef="let dataItem">
        <div class="mat-table-row">
          <div
            class="mat-table-icon-container"
            *ngIf="
              cd.ionIcon &&
              (dataItem[cd.fieldName] === cd.showIconArg || cd.alwaysShowIcon)
            "
          >
            <ion-icon
              [name]="cd.ionIcon"
              [color]="cd.ionIconColor"
              *ngIf="
                cd.ionIcon &&
                (dataItem[cd.fieldName] === cd.showIconArg || cd.alwaysShowIcon)
              "
            ></ion-icon>

            <ion-badge
              color="danger"
              *ngIf="
                cd.hasIconBadge &&
                dataItem[cd.iconBadgeArgField] === cd.iconBadgeArgValue
              "
            ></ion-badge>
          </div>
          <ng-container>
            <span *ngIf="!cd.isDate">{{ dataItem[cd.fieldName] }}</span>
            <span *ngIf="cd.isDate">{{
              dataItem[cd.fieldName] | formatDate: "YYYY-MM-DD HH:mm:ss"
            }}</span>
          </ng-container>
        </div>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let dataItem; columns: displayedColumns; let i = index"
    (click)="selectRow(dataItem)"
  >
</tr>
</table>

<!-- Responsive Card List -->
<div class="responsive-cards" *ngFor="let di of parsedData; let i = index">
  <bh-card
    [ionIcon]="getDataItemIcon(di)"
    [showIconBadge]="checkDataItemBadge(di)"
    [title]="getDataItemTitle(di)"
    (click)="selectRow(di)"
    layout="list"
  >
    <div list-content>
      <div [innerHtml]="getOtherDataItemProperties(di)"></div>
    </div>
  </bh-card>
</div>
