<div
  class="bh-input-wrapper"
  [ngClass]="{
    required:
      (isRequired || required) && !formGroup.controls[formControlName].value
  }"
  [formGroup]="formGroup"
>
  <div class="required-badge" *ngIf="isRequired || required">Required</div>
  <ng-container *ngIf="!viewOnly">
    <!-- Textbox -->
    <div
      *ngIf="type === 'ion-text'"
      [id]="'txt' + formControlName"
      class="input-container"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-input
        type="text"
        [value]="value"
        fill="solid"
        [label]="label"
        [labelPlacement]="position"
        [helperText]="helperText"
        [formControlName]="formControlName"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [maxlength]="maxlength"
        [ngStyle]="{ 'min-width': minWidth }"
        [pattern]="pattern"
        [ngClass]="textTransform"
        (ionBlur)="blurInput(formControlName)"
        (ionChange)="valueChanged(index, label, $event)"
        (keydown)="handleKeyDown($event, 'txt')"
        (keyup)="handleKeyUp($event, 'txt')"
        (paste)="handlePaste($event, 'txt')"
      ></ion-input>
    </div>

    <!-- Textarea -->
    <div
      *ngIf="type === 'ion-textarea'"
      [id]="'txtarea' + formControlName"
      fill="outline"
      class="input-container"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-textarea
        [id]="'ion-textarea-' + formControlName"
        [autoGrow]="true"
        [value]="value"
        fill="solid"
        [label]="label"
        [labelPlacement]="position"
        [helperText]="helperText"
        [formControlName]="formControlName"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [maxlength]="maxlength"
        [ngStyle]="{ 'min-width': minWidth }"
        [pattern]="pattern"
        [ngClass]="textTransform"
        (ionBlur)="blurInput(formControlName)"
        (ionChange)="valueChanged(index, label, $event)"
        (keydown)="handleKeyDown($event, 'txt')"
        (keyup)="handleKeyUp($event, 'txt')"
        (paste)="handlePaste($event, 'txt')"
        (click)="checkForTextEditor()"
      ></ion-textarea>
    </div>

    <!-- Checkbox -->
    <div
      *ngIf="type === 'ion-checkbox'"
      [id]="'ionchkbox' + formControlName"
      fill="outline"
      class="input-container ion-checkbox"
    >
      <ion-checkbox
        labelPlacement="end"
        [formControlName]="formControlName"
        [value]="value"
        [ngClass]="{ 'input-pad-left': indentLeft, textTransform }"
      >
        {{ label }}
      </ion-checkbox>
    </div>

    <!-- Radio button list -->
    <ion-radio-group
      *ngIf="type === 'ion-radio'"
      [formControlName]="formControlName"
      [value]="value"
    >
      <div class="margin-top-16"></div>
      <ion-list-header>
        <ion-label>
          <span
            class="number-bullet"
            [ngClass]="{ answered: formGroup.controls[formControlName].value }"
            *ngIf="index > -1"
            >{{ index + 1 }}</span
          >
          <span class="question-text large-text" [ngClass]="textTransform">{{
            label
          }}</span>
          <span
            class="required-marker"
            *ngIf="required && !formGroup.controls[formControlName].value"
            >*</span
          >
        </ion-label>
      </ion-list-header>
      <div
        *ngFor="let option of parsedSelectOptions"
        [ngClass]="{ 'input-pad-left': indentLeft }"
      >
        <ion-radio
          mode="md"
          slot="start"
          [value]="option.value"
          labelPlacement="end"
          aria-label="Option 1"
        >
          <div class="radio-label" [ngClass]="textTransform">
            {{ option.label }}
          </div>
          <div class="radio-detail">{{ option.detail }}</div>
        </ion-radio>
      </div>
      <div class="margin-bottom-24"></div>
    </ion-radio-group>

    <!-- Ionic DateTime -->
    <div
      *ngIf="type === 'ion-datetime'"
      [id]="'ionDateTime' + formControlName"
      class="ion-datetime-item no-hover"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-label [position]="position" *ngIf="label">
        <span
          class="required-marker fixed"
          *ngIf="required && position === 'fixed'"
          >*</span
        >
        <span class="number-bullet" *ngIf="index > -1">{{ index + 1 }}</span>
        <span class="question-text" [ngClass]="textTransform">{{ label }}</span>
        <span
          class="required-marker"
          *ngIf="required && !formGroup.controls[formControlName].value"
          >*</span
        >
      </ion-label>

      <!-- <ion-datetime-button datetime="ionDateTime"></ion-datetime-button> -->
      <ion-datetime
        id="ionDateTime"
        [formControlName]="formControlName"
        [yearValues]="yearValues"
        (ionBlur)="blurInput(formControlName)"
        (ionChange)="valueChanged(index, label, $event)"
        presentation="date-time"
      ></ion-datetime>
    </div>

    <ion-modal [keepContentsMounted]="true" *ngIf="type === 'ion-datetime'">
      <ng-template> </ng-template>
    </ion-modal>
    <!-- <span>{{ yearValues }}</span> -->

    <!-- Ionic Datepicker -->
    <div
      *ngIf="type === 'ion-date'"
      [id]="'ionDate' + formControlName"
      class="input-container"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-input
        type="date"
        [value]="value"
        fill="solid"
        [label]="label"
        [labelPlacement]="position"
        [helperText]="helperText"
        [formControlName]="formControlName"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [maxlength]="maxlength"
        [ngStyle]="{ 'min-width': minWidth }"
        [pattern]="pattern"
        (ionBlur)="blurInput(formControlName)"
        (ionChange)="valueChanged(index, label, $event)"
        (keydown)="handleKeyDown($event, 'ionDate')"
        (keyup)="handleKeyUp($event, 'ionDate')"
        (paste)="handlePaste($event, 'ionDate')"
      ></ion-input>
    </div>

    <!-- Ionic Email -->
    <div
      *ngIf="type === 'ion-email'"
      [id]="'ionEmail' + formControlName"
      fill="outline"
      class="input-container"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-input
        type="email"
        [pattern]="pattern"
        [value]="value"
        fill="solid"
        [label]="label"
        [labelPlacement]="position"
        [helperText]="helperText"
        [formControlName]="formControlName"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [ngStyle]="{ 'min-width': minWidth }"
        (ionBlur)="blurInput(formControlName)"
        (ionChange)="valueChanged(index, label, $event)"
        (keydown)="handleKeyDown($event, 'ionEmail')"
        (keyup)="handleKeyUp($event, 'ionEmail')"
        (paste)="handlePaste($event, 'ionEmail')"
      ></ion-input>
    </div>

    <!-- Ionic Number -->
    <div
      *ngIf="type === 'ion-number'"
      [id]="'ionNumber' + formControlName"
      fill="outline"
      class="input-container"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-input
        type="number"
        [pattern]="pattern"
        [min]="min"
        [max]="max"
        [value]="value"
        fill="solid"
        [label]="label"
        [labelPlacement]="position"
        [helperText]="helperText"
        [formControlName]="formControlName"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [ngStyle]="{ 'min-width': minWidth }"
        (ionBlur)="blurInput(formControlName)"
        (ionChange)="valueChanged(index, label, $event)"
        (keydown)="handleKeyDown($event, 'ionNumber')"
        (keyup)="handleKeyUp($event, 'ionNumber')"
        (paste)="handlePaste($event, 'ionNumber')"
      ></ion-input>
    </div>

    <!-- Ionic Phone -->
    <div
      *ngIf="type === 'ion-phone'"
      [id]="'ionPhone' + formControlName"
      fill="outline"
      class="input-container"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-input
        type="tel"
        [value]="value"
        fill="solid"
        [label]="label"
        [labelPlacement]="position"
        [helperText]="helperText"
        [formControlName]="formControlName"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [maxlength]="maxlength"
        [ngStyle]="{ 'min-width': minWidth }"
        [pattern]="pattern"
        (ionBlur)="blurInput(formControlName)"
        (ionChange)="valueChanged(index, label, $event)"
        (keydown)="handleKeyDown($event, 'ionPhone')"
        (keyup)="handleKeyUp($event, 'ionPhone')"
        (paste)="handlePaste($event, 'ionPhone')"
      ></ion-input>
    </div>

    <!-- Angular Material Datepicker -->
    <div
      *ngIf="type === 'date'"
      lines="none"
      class="margin-top-16 background-transparent material-datepicker-item item-input"
      class="input-container"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-label [position]="position" *ngIf="label">
        <span
          class="required-marker fixed"
          *ngIf="required && position === 'fixed'"
          >*</span
        >
        <span class="question-text">{{ label }}</span>
        <span
          class="required-marker"
          *ngIf="required && !formGroup.controls[formControlName].value"
          >*</span
        >
      </ion-label>
      <mat-form-field
        [ngClass]="{ 'input-pad-left': indentLeft }"
        class="material-datepicker"
      >
        <div
          class="date-placeholder"
          *ngIf="
            formGroup.controls[formControlName].value === null ||
            formGroup.controls[formControlName].value === 'NaN' ||
            !formGroup.controls[formControlName].value
          "
        >
          {{ placeholder }} {{ formGroup.controls[formControlName].value }}
        </div>

        <input
          matInput
          [matDatepicker]="picker"
          [formControlName]="formControlName"
          autocomplete="off"
          [ngStyle]="{ 'min-width': minWidth }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <!-- Ion-select -->
    <div
      *ngIf="type === 'ion-select'"
      class="input-container"
      [id]="'select' + formControlName"
      fill="outline"
    >
      <ion-icon
        [name]="ionIcon"
        [color]="ionIconColor"
        [src]="ionIconSource"
        slot="start"
        *ngIf="ionIcon || ionIconSource"
      ></ion-icon>
      <ion-select
        interface="popover"
        [value]="value"
        fill="solid"
        [label]="label"
        [labelPlacement]="position"
        [formControlName]="formControlName"
        [placeholder]="placeholder"
        [maxlength]="maxlength"
        [ngStyle]="{ 'min-width': minWidth }"
        [pattern]="pattern"
        (ionBlur)="blurInput(formControlName)"
        (ionChange)="valueChanged(index, label, $event)"
        (ionBlur)="blurInput(formControlName)"
      >
        <ion-select-option value="" *ngIf="includeDefaultBlankValue === true"
          >(Select one)</ion-select-option
        >
        <ng-container *ngIf="parsedSelectOptions">
          <ion-select-option
            *ngFor="let option of parsedSelectOptions"
            [value]="option.value"
          >
            {{ option.label }}
          </ion-select-option>
        </ng-container>
      </ion-select>
    </div>

    <!-- Select -->
    <div
      *ngIf="type === 'select'"
      class="margin-top-8 no-hover border-adjust item-input shaded-background"
      [id]="'nativeSelect' + formControlName"
    >
      <ion-label [position]="position" *ngIf="label">
        <span
          class="required-marker fixed"
          *ngIf="required && position === 'fixed'"
          >*</span
        >
        <span class="number-bullet" *ngIf="index > -1">{{ index + 1 }}</span>
        <span class="question-text">{{ label }}</span>
        <span
          class="required-marker"
          *ngIf="required && !formGroup.controls[formControlName].value"
          >*</span
        >
      </ion-label>
      <div class="native-select-wrapper">
        <div
          class="select-placeholder"
          *ngIf="!formGroup.controls[formControlName].value === null"
        >
          {{ placeholder }}
        </div>
        <select
          [formControlName]="formControlName"
          [value]="value"
          (change)="valueChanged(index, label, $event)"
          (ionBlur)="blurInput(formControlName)"
          [ngStyle]="{ 'min-width': minWidth }"
        >
          <option value=""></option>
          <option
            *ngFor="let option of parsedSelectOptions"
            [value]="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <bh-validation-error
      *ngIf="validationMessages"
      [validationMessages]="validationMessages"
      [formGroup]="formGroup"
      [formControlName]="formControlName"
      [submitAttempted]="submitAttempted"
    ></bh-validation-error>

    <!-- <div class="helper-text" *ngIf="!viewOnly && helperText">
      <ion-icon name="information-circle"></ion-icon> {{ helperText }}
    </div> -->

    <div class="separator-line" *ngIf="showSeparatorLine"></div>
  </ng-container>
  <ng-container *ngIf="viewOnly">
    <div class="view-only-item">
      <ion-label>
        {{ label }}
      </ion-label>
      <ion-text *ngIf="type !== 'select'">
        {{ viewOnlyValue }}
      </ion-text>
      <ion-text *ngIf="type === 'select' && viewOnlySelectLabel">
        {{ viewOnlySelectLabel }}
      </ion-text>
    </div>
  </ng-container>
</div>
<div class="margin-bottom-16"></div>
