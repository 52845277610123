<div
  class="search-container"
  [ngClass]="{ compact: layout === 'compact', disabled: disabled, 'focused': isFocused }"
>
  <input
    type="text"
    spellcheck="false"
    [(ngModel)]="searchTerm"
    [placeholder]="placeholder"
    (keyup)="search()"
    (focus)="onFocus(true)"
    (blur)="onFocus(false)"
    [disabled]="disabled"
    #searchInput
  />
  <ion-icon
    name="search-outline"
    color="step-300"
    *ngIf="!searchTerm"
  ></ion-icon>
  <a href="javascript:void(0)" (click)="clearSearch()" *ngIf="searchTerm"
    ><ion-icon name="close-outline"></ion-icon
  ></a>
</div>
<div class="search-button-container" *ngIf="showSearchButton">
  <ion-button (click)="search()">Search</ion-button>
</div>
