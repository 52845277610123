import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription, firstValueFrom } from 'rxjs';
import { SelectOption } from 'src/app/models/_core/select-option';
import { AllAppsData, VerlockerData } from 'src/app/models/locker-data';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { UsersService } from 'src/app/services/users/users.service';
import { VerlockerService } from 'src/app/services/verlocker/verlocker.service';

@Component({
  selector: 'app-manage-verlocker-editor',
  templateUrl: './manage-verlocker-editor.page.html',
  styleUrls: ['./manage-verlocker-editor.page.scss'],
})
export class ManageVerlockerEditorPage implements OnInit {
  @Input() editMode: 'new' | 'edit' = 'new';
  //@Input() userId: string;
  @Input() selectedVerlockerData: VerlockerData;
  user: User;
  initUserName = '';
  modalTitle = '';
  formReady = false;
  // isChecking = false;
  checkStatus: 'not-checked' | 'checking' | 'new' | 'exists' = 'not-checked';
  checkStatusVersion: 'not-checked' | 'checking' | 'new' | 'exists' = 'not-checked';
  checkTimer = null;
  authUser: User;

  form1: FormGroup = this.formBuilder.group({
    appId: [null, Validators.required],
    active: [null, Validators.required],
    updateDescription: [null],
    version: [null, Validators.required],
    expireDate: [null],
    releaseDate: [null],
    requestedDate: [null],
  });

  activeOptions: SelectOption[] = [
    {
      label: 'Yes',
      value: '1',
    },
    {
      label: 'No',
      value: '0',
    },
  ];

  subscriptions: Subscription[] = [];
  showErrorMessage = false;
  submitAttempted = false;
  validationMessages = {
    appId: [{ type: 'required', message: 'App ID is required.' }],
    active: [{ type: 'required', message: 'Active property must be set' }],
    version: [{ type: 'required', message: 'Version is required.' }],
  };

  roleOptions: SelectOption[] = [
    {
      label: 'General User',
      value: 'USER',
    },
    {
      label: 'Content Admin',
      value: 'ADMIN',
    },
    {
      label: 'System Admin',
      value: 'SYS_ADMIN',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notifications: NotificationsService,
    private verlockerService: VerlockerService
  ) {}

  ngOnInit() {
    this.authUser = this.authService.getAuthUser();
    this.setEditMode();
    this.subscribeToControls();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
      s = null;
    });
  }

  setEditMode() {
    if (this.editMode === 'edit') {
      this.modalTitle = 'Edit App Version';
      this.loadData();
    } else {
      if(this.selectedVerlockerData) {
        this.modalTitle = 'New App Version';
        this.form1.controls.appId.setValue(this.selectedVerlockerData.appId);
        this.form1.controls.active.setValue('1');
      }
    }
  }

  subscribeToControls() {
    if (this.subscriptions.length === 0) {
      this.subscriptions.push(
        this.form1.controls.version.valueChanges.subscribe(async (val) => {
          console.log('val', val);
          this.checkForExistingVersion(val);
        })
      );
      this.subscriptions.push(
        this.form1.controls.appId.valueChanges.subscribe(async (val) => {
          console.log('val', val);
          this.checkForExistingAppId(val);
        })
      );
    }
  }

  async checkForExistingAppId(appId: string) {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    if (appId && !this.checkTimer) {
      this.checkTimer = setTimeout(async () => {
        this.formReady = false;
        this.checkStatus = 'checking';
        try {
          //const foundVerlocker = this.selectedVerlockerData.
          const res = await firstValueFrom(
            this.verlockerService.loadAllVerlockerData()
          );
            console.log('verlocker res', res);
          if (res.apps && res.apps.length > 0) {
            const versionExists = res.apps.filter( (a: AllAppsData) => a.appId.toLowerCase() === appId.toLowerCase()).length > 0;
            console.log('version exists', versionExists);
            if (versionExists) {
              this.formReady = false;
              this.checkStatus = 'exists';
            } else {
              this.formReady = true;
              this.checkStatus = 'new';
            }
          } else {
            this.checkStatus = 'new';
            this.formReady = true;
          }
        } catch (err) {
          // this.modalCtrl.dismiss();
          this.notifications.handleError(
            err,
            'manage-verlocker-editor.checkForExistingLockerData'
          );
        }
      }, 500);
    } else {
      this.checkStatus = 'not-checked';
    }
  }


  async checkForExistingVersion(version: string) {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    if (version && !this.checkTimer) {
      this.checkTimer = setTimeout(async () => {
        this.formReady = false;
        this.checkStatusVersion = 'checking';
        let res: any;
        try {
          //const foundVerlocker = this.selectedVerlockerData.
          if(this.selectedVerlockerData) {
             res = await firstValueFrom(
              this.verlockerService.getVerlockerDataForSingleApp(
                this.selectedVerlockerData.appId
              )
            );
          } else {
            res = await firstValueFrom(
              this.verlockerService.getVerlockerDataForSingleApp(
                this.form1.controls.appId.value
              )
            );
          }
          console.log('verlocker res', res);
        if (res.verlocker && res.verlocker.length > 0) {
          const versionExists = res.verlocker.filter( (v: VerlockerData) => v.version === version).length > 0;
          console.log('version exists', versionExists);
          if (versionExists) {
            this.formReady = false;
            this.checkStatusVersion = 'exists';
          } else {
            this.formReady = true;
            this.checkStatusVersion = 'new';
          }
        } else {
          this.checkStatusVersion = 'new';
          this.formReady = true;
        }
        } catch (err) {
          // this.modalCtrl.dismiss();
          this.notifications.handleError(
            err,
            'manage-verlocker-editor.checkForExistingLockerData'
          );
        }
      }, 500);
    } else {
      this.checkStatus = 'not-checked';
    }
  }

  async loadData() {
    try {
      if (this.selectedVerlockerData) {
        this.formReady = true;
        this.form1.controls.appId.setValue(this.selectedVerlockerData.appId);
        this.form1.controls.active.setValue(
          this.selectedVerlockerData.latestVersion.toString()
        );
        this.form1.controls.updateDescription.setValue(
          this.selectedVerlockerData.updateDescription
        );
        this.form1.controls.version.setValue(
          this.selectedVerlockerData.version
        );
        this.form1.controls.expireDate.setValue(
          moment(this.selectedVerlockerData.expireDate, 'MM/DD/YYYY').format('YYYY-MM-DD'));
        this.form1.controls.releaseDate.setValue(
          moment(this.selectedVerlockerData.releaseDate, 'MM/DD/YYYY').format('YYYY-MM-DD'));
        this.form1.controls.requestedDate.setValue(
          moment(this.selectedVerlockerData.requestedDate,'MM/DD/YYYY').format('YYYY-MM-DD'));
      }
    } catch (err) {
      this.modalCtrl.dismiss();
      this.notifications.handleError(err, 'manage-user-editor.loadData');
    }
  }

  async save() {
    this.submitAttempted = true;
    if (this.form1.valid) {
      try {
        if (this.editMode === 'edit' && this.selectedVerlockerData.appId) {
          this.selectedVerlockerData.appId = this.form1.controls.appId.value;
          this.selectedVerlockerData.latestVersion =
            this.form1.controls.active.value;
          this.selectedVerlockerData.updateDescription =
            this.form1.controls.updateDescription.value;
          this.selectedVerlockerData.version =
            this.form1.controls.version.value;
          this.selectedVerlockerData.expireDate =
          this.form1.controls.expireDate.value !== null ? moment(this.form1.controls.expireDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;
          this.selectedVerlockerData.releaseDate =
          this.form1.controls.releaseDate.value !== null ? moment(this.form1.controls.releaseDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY'): null;
          this.selectedVerlockerData.requestedDate =
          this.form1.controls.requestedDate.value !== null ? moment(this.form1.controls.requestedDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;

          console.log('expireDate', this.selectedVerlockerData.expireDate, 'releaseDate', this.selectedVerlockerData.releaseDate, 'requestedDate', this.selectedVerlockerData.requestedDate );


          if(this.selectedVerlockerData.expireDate === 'Invalid date') {
            this.selectedVerlockerData.expireDate = null;
          }
          if(this.selectedVerlockerData.releaseDate === 'Invalid date') {
            this.selectedVerlockerData.releaseDate = null;
          }
          if(this.selectedVerlockerData.requestedDate === 'Invalid date') {
            this.selectedVerlockerData.requestedDate = null;
          }

          console.log('expireDate', this.selectedVerlockerData.expireDate, 'releaseDate', this.selectedVerlockerData.releaseDate, 'requestedDate', this.selectedVerlockerData.requestedDate );

          await firstValueFrom(this.verlockerService.saveVerlocker(this.selectedVerlockerData));
          this.notifications.stopLoading();
          this.analytics.clickEvent('manage-verlocker-editor: update', '');
          this.notifications.showToast('Update successful.');
          this.modalCtrl.dismiss({ isSaved: true });
        } else {
          const newLockerData: VerlockerData = {
            appId: this.form1.controls.appId.value,
            version:this.form1.controls.version.value,
            latestVersion: this.form1.controls.active.value,
            updateDescription: this.form1.controls.updateDescription.value,
            releaseDate: moment(this.form1.controls.releaseDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY') !== 'Invalid date' ? moment(this.form1.controls.releaseDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
            requestedDate: moment(this.form1.controls.requestedDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY') !== 'Invalid date' ? moment(this.form1.controls.requestedDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
            expireDate: moment(this.form1.controls.expireDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY') !== 'Invalid date' ?  moment(this.form1.controls.expireDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
          }
          const res = await firstValueFrom(this.verlockerService.saveVerlocker(newLockerData));
          this.analytics.clickEvent('manage-verlocker-editor: save', '');
          this.notifications.stopLoading();
          this.notifications.showToast('Saved successfully.');
          newLockerData.appId = res.appId;
          this.modalCtrl.dismiss({ isSaved: true, lockerData: newLockerData });
        }
      } catch (err) {
        this.notifications.handleError(err, 'manage-tag-editor.save');
      }
    }
  }

  dismiss() {
    this.analytics.clickEvent('manage-tag-editor: dismiss', '');
    this.modalCtrl.dismiss();
  }
}
