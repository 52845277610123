<div class="validation-errors" [ngClass]="{'paddingNone': padding === 'none'}">
  <ng-container *ngFor="let validation of validationMessages[formControlName]">
    <div
      class="error-message"
      *ngIf="
        formGroup.get(formControlName).hasError(validation.type.toLowerCase()) &&
        (formGroup.get(formControlName).dirty ||
          formGroup.get(formControlName).touched ||
          submitAttempted)
      "
    >
      <ion-icon name="alert"></ion-icon>
      {{ validation.message }}
    </div>
  </ng-container>
</div>
