import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ManageUserEditorPage } from '../manage-user-editor/manage-user-editor.page';
import { AppDataService } from 'src/app/services/app-data/app-data.service';
import { AppData } from 'src/app/models/app-data';
import { AppAcess } from 'src/app/models/app-access';
import { ManageAppEditorPage } from '../manage-app-editor/manage-app-editor.page';
import { ManageAppAccessEditorPage } from '../manage-app-access-editor/manage-app-access-editor.page';

@Component({
  selector: 'app-manage-app-access',
  templateUrl: './manage-app-access.page.html',
  styleUrls: ['./manage-app-access.page.scss'],
})
export class ManageAppAccessPage {
  initUsers: User[] = [];
  users: User[] = [];
  searchTerm = '';
  isLoading = true;
  initApps: AppData[] = [];
  appData: AppData[] = [];
  appAccessData: AppAcess[] = [];
  selectedAppAccess: AppAcess[] = [];
  displayMode: 'new' | 'edit' | 'all-apps' | 'access' = 'all-apps';
  titleAccess: string = '';
  sortOrder: string = '';
  filterValue: string;
  filtering = false;
  filteringText = '';
  sorting = false;
  sortingText = '';
  foundAll = false;
  constructor(
    private usersService: UsersService,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private appDataService: AppDataService
  ) { }

  async ionViewWillEnter() {
    await this.loadAllAppData();
  }

  async loadAllAppData(): Promise<boolean> {
    try {
      this.isLoading = true;
      await firstValueFrom(this.appDataService.getAllApps()).then( data => {
        console.log('res app data', data.apps);
        if (data && Array.isArray(data.apps) && data.apps.length > 0) {
          this.initApps = data.apps;
          console.log('load All App Data', this.initApps);
          this.sortUsers();
          this.appData = Object.assign([], this.initApps);
        }
        this.isLoading = false;
      });
      return Promise.resolve(true);
    } catch (err) {
      this.notifications.handleError(err, 'loadAllAppData');
      return Promise.reject();
    }
  }

  loadAppAcces() {

  }

  pickColor(app: AppData) {
    let color: string = '';
    switch (app.appTypeText.split(' ')[0].toLowerCase()) {
      case 'employee':
        color = 'primary';
        break;
      case 'patient':
        color = 'secondary';
        break;
      case 'partner':
        color = 'tertiary';
        break;
      default:
        color = 'warning'
        break;
    }
    return color;
  }

  sortUsers() {
    this.initUsers.sort((a, b) => {
      const aName = a.lastName + ',' + a.firstName;
      const bName = b.lastName + ',' + b.firstName;
      if (aName.toLowerCase() < bName.toLowerCase()) return -1;
      if (aName.toLowerCase() > bName.toLowerCase()) return 1;
      return 0;
    });
  }



  async selectApp(app: AppData): Promise<boolean> {
    try {
      this.titleAccess = app.appId;
      this.isLoading = true;
        await firstValueFrom(this.appDataService.loadAccessData(app.appId)).then( data => {
          console.log('res app access', data.apps);

          if (data && Array.isArray(data.apps) && data.apps.length > 0) {
            this.appAccessData = data.apps.filter(a => a.appId === app.appId);
            // this.selectedAppAccess = this.appAccessData.fil
            this.foundAll = this.appAccessData.filter( ap => ap.en === 'ALL').length > 0;
            console.log('load All App Data', this.appAccessData);
            // this.sortUsers();
            // this.appData = Object.assign([], this.appAccessData);
          }
          this.displayMode = 'access';
          this.isLoading = false;
        });
        return Promise.resolve(true);
    } catch(err) {
      this.notifications.handleError(err, 'loadAllAppData');
      return Promise.reject();
    }
    }


    async addAccess() {
      this.analytics.clickEvent('Manage-Users > Add', '');
      const modal = await this.modalCtrl.create({
        component: ManageAppAccessEditorPage,
        cssClass: 'edit-modal',
        componentProps: {
          editMode: 'new',
          selectedAccess: this.appAccessData[0],
          foundAll: this.foundAll
        }
      });

      modal.onDidDismiss().then(async (data) => {
        if (data && data.data) {
          if (data.data.isSaved && data.data.selectedApp) {
            await this.selectApp(data.data.selectedApp);
          } else if (data.data.dismiss) {
            this.modalCtrl.dismiss();
          }
        }
      });

      await modal.present();
    }

    async editAccess(access: AppAcess) {
    console.log('editing access', access);
    this.analytics.clickEvent('Manage-Users > Edit', access.appId);
    const modal = await this.modalCtrl.create({
      component: ManageAppAccessEditorPage,
      cssClass: 'edit-modal',
      componentProps: {
        editMode: 'edit',
        selectedAccess: access,
      }
    });
    modal.onDidDismiss().then(async (data) => {
      if (data && data.data) {
        if (data.data.isSaved) {
          //await this.loadData();
          this.search(this.searchTerm);
        } else if (data.data.dismiss) {
          this.modalCtrl.dismiss();
        }
      }
    });
    await modal.present();
    }

  search(ev) {
    this.searchTerm = (ev.detail) ? ev.detail.value : this.searchTerm;
    const qry = this.searchTerm.toLowerCase();
    if (qry) {
      for (const a of this.initApps) {
        a.hide = a.appId.toLowerCase().indexOf(qry) === -1 &&
          a.name.toLowerCase().indexOf(qry) === -1
      }
    } else {
      for (const a of this.appData) {
        a.hide = false;
      }
    }
    console.log('search', this.searchTerm, this.appData.filter(a => !a.hide));
  }

  dismiss() {
    this.analytics.clickEvent('manage-user-list: dismiss', '');
    this.modalCtrl.dismiss();
  }

  back() {
    this.displayMode = 'all-apps';
  }

  saveSort() {
    this.sortOrder = 'ascending';
    console.log('sort', this.sortOrder);
    this.sorting = true;
    this.sortingText = this.sortOrder;
    //const apps = this.initApps;
    //console.log('app Data', this.appData);

    this.appData = this.appData.sort( (a, b) => this.sortByName(a.appId, b.appId, this.sortOrder));
  }

  sortByName(a, b, order) {
    console.log('order', order);
    const aName = a.toUpperCase();
    const bName = b.toUpperCase();
    if(order === 'ascending') {
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    } else {
      if (aName < bName) {
        return 1;
      }
      if (aName > bName) {
        return -1;
      }
      return 0;
    }
  }

  saveFilter() {
    this.filtering = true;
    this.filteringText = this.filterValue;
    this.appData = this.appData.filter( app => app.appTypeText.split(' ')[0].toLowerCase() === this.filterValue);
  }

  removeFilters() {
    this.filtering = false;
    this.filterValue = null;
    this.sorting = false;
    this.sortOrder = null;
    this.appData = Object.assign([], this.initApps);
    //this.appData = this.initApps;
  }


}
