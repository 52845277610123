import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { Subscription, firstValueFrom } from 'rxjs';
import { SelectOption } from 'src/app/models/_core/select-option';
import { AppAcess } from 'src/app/models/app-access';
import { AppData } from 'src/app/models/app-data';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { StorageService } from 'src/app/services/_core/storage/storage.service';
import { AppDataService } from 'src/app/services/app-data/app-data.service';

@Component({
  selector: 'app-manage-app-access-editor',
  templateUrl: './manage-app-access-editor.page.html',
  styleUrls: ['./manage-app-access-editor.page.scss'],
})
export class ManageAppAccessEditorPage implements OnInit {

  @Input() editMode: string;
  @Input() selectedAccess: AppAcess;
  hasBeenChanged = false;
  modalTitle: string = '';
  app: AppData = {};
  showDeleteMessage = false;
  isAllAccess: boolean = false;
  formReady = false;
  accessRow: any[] = [];
  initUserName = '';
  // isChecking = false;
  checkStatus: 'not-checked' | 'checking' | 'new' | 'exists' = 'not-checked';
  checkTimer = null;
  subscriptions: Subscription[] = [];
  foundAll = false;


  form1: FormGroup = this.formBuilder.group({
    appId: ['', Validators.required],
    en: ['', Validators.required],
    userId: ['', Validators.required],
  })

  initialFormState = {
    appId: '',
    en: '',
    userId: ''
  }

   // Define validation messages
   validationMessages = {
    roleGroup: [{ type: "required", message: "A role group name is required." }],
    active: [{ type: 'optional' }]
  };

   // Submission State
   submitAttempted = false;

   // View only State
   viewOnly = true;

  constructor(
    public notifications: NotificationsService,
    private menu: MenuController,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private helpers: HelperUtilitiesService,
    private appDataService: AppDataService,
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.setEditMode();
    this.subscribeToControls();
  }

  async setEditMode(): Promise<void> {
    if (this.editMode === 'edit') {
      this.modalTitle = 'Editing access for ' + this.helpers.convertTitleCase(this.selectedAccess.appId);
      this.loadAppAccessData();
    } else {
      this.form1.controls.appId.setValue(this.selectedAccess.appId);
    }
    return Promise.resolve();
  }

  async loadAppAccessData() {
    this.form1.controls.appId.setValue(this.selectedAccess.appId);
    this.form1.controls.en.setValue(this.selectedAccess.en);
    this.form1.controls.userId.setValue(this.selectedAccess.userId);
  }


  subscribeToControls() {
    if (this.subscriptions.length === 0) {
      this.subscriptions.push(
        this.form1.controls.en.valueChanges.subscribe(async (val) => {
          console.log('val', val);
          this.checkForExistingAccess(val);
        })
      );
    }
  }

  async checkForExistingAccess(enNumber: string) {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    if (enNumber && !this.checkTimer) {
      this.checkTimer = setTimeout(async () => {
        this.formReady = false;
        this.checkStatus = 'checking';
        try {
          const res = await firstValueFrom(
            this.appDataService.loadAccessData(
              this.selectedAccess.appId
            )
          );
            console.log('access res', res);
          if (res.apps && res.apps.length > 0) {
            const accessExists = res.apps.filter( (a: AppAcess) => a.en === enNumber).length > 0;
            console.log('version exists', accessExists);
            if (accessExists) {
              this.formReady = false;
              this.checkStatus = 'exists';
            } else {
              this.formReady = true;
              this.checkStatus = 'new';
            }
          } else {
            this.checkStatus = 'new';
            this.formReady = true;
          }

          console.log('form Ready?', this.formReady);

        } catch (err) {
          // this.modalCtrl.dismiss();
          this.notifications.handleError(
            err,
            'manage-verlocker-editor.checkForExistingLockerData'
          );
        }
      }, 500);
    } else {
      this.checkStatus = 'not-checked';
    }
  }


  async save() {
    this.submitAttempted = true;
    if (this.form1.valid) {
      this.notifications.startLoading('Saving your work');
      try {
        if (this.editMode === 'edit' && this.selectedAccess.appId) {
          this.selectedAccess.en = this.form1.controls.en.value;
          this.selectedAccess.userId = this.form1.controls.userId.value;
          await firstValueFrom(this.appDataService.updateAccessData(this.selectedAccess));
          this.notifications.stopLoading();
          this.analytics.clickEvent('manage-app-access-editor: update', '');
          this.notifications.showToast('Update successful.');
          this.modalCtrl.dismiss({ isSaved: true });
        } else {
          const newAppAccess: AppAcess = {
            appId: this.selectedAccess.appId,
            en: this.form1.controls.en.value,
            userId: this.form1.controls.userId.value,
            updateDate: this.selectedAccess.updateDate,
          }
          const res = await firstValueFrom(this.appDataService.updateAccessData(newAppAccess));
          console.log('res', res);
          newAppAccess.appId = res.appId;
          this.analytics.clickEvent('manage-app-access-editor: save', '');
          this.notifications.stopLoading();
          this.notifications.showToast('Saved successfully.');
          this.modalCtrl.dismiss({ isSaved: true, selectedApp: newAppAccess });
        }
      } catch (err) {
        this.notifications.stopLoading();
        this.notifications.handleError(err, 'manage-content-editor.save');
      }
    }
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    if(this.hasBeenChanged !== false) {
      this.modalCtrl.dismiss();
    } else {
      this.modalCtrl.dismiss();
    }
  }

  showFeatures(event) {
      this.app.showFeatures = event;
  }


  triggerDelete() {
      this.showDeleteMessage = true;
  }

  addAccessRow(form) {
      this.accessRow.push(form);
      console.log('acessrow', this.accessRow);

  }

  accessChanged(event) {
    if(event.detail.checked) {
      this.formReady = true;
      this.form1.controls.en.setValue('ALL');
      this.form1.controls.userId.setValue('ALL');
    } else {
      this.formReady = false;
      this.form1.controls.en.setValue('');
      this.form1.controls.userId.setValue('');
    }
  }

}
