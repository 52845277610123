<ion-header class="modal-header mobile-only">
  <ion-toolbar>
    <ion-title>
      <div class="icon-and-title">
        <ion-icon name="person-outline"></ion-icon>
        {{ modalTitle }}
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="two-panel">
    <div class="information-panel desktop-only">
      <h1>{{ modalTitle }}</h1>
      <p>Edit the properties of this app version.</p>
    </div>
    <div class="form-panel">
      <div
        class="form-panel-inner"
        [ngClass]="{'with-header': editMode === 'edit'}"
      >
        <div
          class="form-panel-header desktop-only-flex"
          *ngIf="editMode === 'edit'"
        ></div>
        <a
          href="javascript:void(0)"
          (click)="dismiss()"
          class="close-button desktop-only"
        >
          <ion-icon name="close-outline"></ion-icon>
        </a>
        <ng-container>
          <form [formGroup]="form1">
            <div class="user-impersonate-container">
              <bh-input
                [formGroup]="form1"
                type="ion-text"
                formControlName="appId"
                placeholder=""
                label="App ID"
                position="stacked"
                [value]=""
                [readonly]="editMode === 'edit'"
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
            </div>
            <ng-container
            *ngIf="form1.controls.appId.value && editMode === 'new'"
          >
            <div class="check-status" *ngIf="checkStatus === 'checking'">
              <ion-spinner name="crescent" size="small"></ion-spinner>
              Checking for existing app Id
            </div>

            <div class="check-status success" *ngIf="checkStatus === 'new'">
              <ion-icon name="checkmark-circle-outline"></ion-icon>
              This app ID can be added.
            </div>

            <div
              class="check-status warning"
              *ngIf="checkStatus === 'exists'"
            >
              <ion-icon name="alert-circle-outline"></ion-icon>
              <span>This app Id already exists.</span>
            </div>
          </ng-container>
            <ng-container>
              <bh-input
              [formGroup]="form1"
              type="ion-select"
              formControlName="active"
              placeholder=""
              label="Active"
              position="stacked"
              [selectOptions]="activeOptions"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

              <bh-input
                [formGroup]="form1"
                type="ion-text"
                formControlName="updateDescription"
                placeholder=""
                label="Update Description"
                position="stacked"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>

              <bh-input
                [formGroup]="form1"
                type="ion-text"
                formControlName="version"
                placeholder=""
                label="Version"
                position="stacked"
                [selectOptions]="roleOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
              <ng-container
              *ngIf="form1.controls.version.value"
            >
              <div class="check-status" *ngIf="checkStatusVersion === 'checking'">
                <ion-spinner name="crescent" size="small"></ion-spinner>
                Checking for existing version
              </div>

              <div class="check-status success" *ngIf="checkStatusVersion === 'new'">
                <ion-icon name="checkmark-circle-outline"></ion-icon>
                This version can be added.
              </div>

              <div
                class="check-status warning"
                *ngIf="checkStatusVersion === 'exists'"
              >
                <ion-icon name="alert-circle-outline"></ion-icon>
                <span>This version is already exists.</span>
              </div>
            </ng-container>
              <bh-input
                [formGroup]="form1"
                type="ion-date"
                formControlName="expireDate"
                placeholder=""
                label="Exipire Date"
                position="stacked"
                [selectOptions]="roleOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
              <bh-input
                [formGroup]="form1"
                type="ion-date"
                formControlName="releaseDate"
                placeholder=""
                label="Release Date"
                position="stacked"
                [selectOptions]="roleOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
              <bh-input
                [formGroup]="form1"
                type="ion-date"
                formControlName="requestedDate"
                placeholder=""
                label="Requested Date"
                position="stacked"
                [selectOptions]="roleOptions"
                [value]=""
                [validationMessages]="validationMessages"
                [submitAttempted]="submitAttempted"
              ></bh-input>
            </ng-container>

          </form>
        </ng-container>
        <div class="form-panel-footer desktop-only">
          <div class="form-panel-footer-inner">
            <ion-button
              [disabled]="!form1.valid || !formReady"
              (click)="save()"
              >Save</ion-button
            >
            <ion-button fill="outline" (click)="dismiss()">Discard</ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="mobile-only">
  <ion-button [disabled]="!form1.valid || !formReady" (click)="save()"
    >Save</ion-button
  >
</ion-footer>
