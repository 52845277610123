<ion-header>
  <ion-toolbar>
    <ion-title>
      <strong>
        <span *ngIf="editMode === 'all'">Manage App Versions</span>
        <span *ngIf="editMode === 'selected'">Manage Versions For {{titleVerlocker}}</span>
      </strong>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()" shape="round">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="manage-page">
  <div class="toolbar" *ngIf="editMode === 'all'">
    <bh-search-bar
      placeholder="Search App Versions"
      [searchTerm]="searchTerm"
      [showSearchButton]="false"
      (searchEvent)="filter($event)"
    ></bh-search-bar>
    <ion-button fill="clear" (click)="addVerlocker()">
      <ion-icon name="add-outline" slot="start"></ion-icon>
      New App Version
    </ion-button>
    <ion-button fill="clear" (click)="loadVerlockerData()">
      <ion-icon name="refresh-outline" slot="start"></ion-icon>
      Refresh
    </ion-button>
  </div>
  <bh-message-banner ionIconName="filter-outline" *ngIf="isFiltering">
    You are filtering results. &nbsp;
    <a href="javascript:void(0)" (click)="filter(null)">Clear</a>
  </bh-message-banner>
  <div class="action-container">
    <a href="javascript:void(0)" class="back-link" (click)="back()" *ngIf="editMode === 'selected'">
      <ion-icon name="arrow-back-circle-outline"></ion-icon>
      Back to All
    </a>
    <div class="add-button-wrapper" *ngIf="editMode === 'selected'">
      <ion-button fill="solid" (click)="addLockerData()">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        New App Version
      </ion-button>
    </div>
  </div>
  <div class="table-container">
    <ng-container *ngIf="editMode === 'all'">
      <bh-spinner
        type="skeleton"
        [skeletonItemCount]="10"
        *ngIf="isLoading"
      ></bh-spinner>
      <ion-item lines="none" class="table-header">
        <!-- <ion-label class="ion-text-start">App</ion-label> -->
        <ion-label class="ion-text-start">App ID</ion-label>
        <ion-label class="ion-text-end">App Store Name</ion-label>
      </ion-item>
      <ng-container *ngFor="let ad of allAppsData">
        <ion-item button (click)="selectLockerData(ad)">

          <!-- <ion-label>
            <div class="ion-text-start">{{ ad?.appId}} </div>
          </ion-label> -->
          <ion-label>
            <ion-icon name="laptop-outline"></ion-icon>
            <div class="ion-text-start">{{ ad?.appId}} </div>
            <!-- <div class="details">{{  }}</div> -->
          </ion-label>
          <ion-label>
            <div class="ion-text-end">{{ ad?.appStoreName}} </div>
            <!-- <div class="details">{{  }}</div> -->
          </ion-label>
          <!-- <ion-badge [color]="pickColor(a)">{{a.appTypeText}}</ion-badge> -->
        </ion-item>
      </ng-container>
      <!-- <bh-data-grid
        [columnDefinitions]="columnDefinitions"
        [data]="allAppsData"
        (clickEvent)="selectLockerData($event)"
      >
      </bh-data-grid> -->
    </ng-container>
    <ng-container *ngIf="editMode === 'selected'">
      <bh-data-grid
        [columnDefinitions]="selectedAppColumnDefinitions"
        [data]="verlockerData"
        (clickEvent)="editLockerData($event)"
      >
      </bh-data-grid>
    </ng-container>
  </div>
</div>
