import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';

/**
 * ID: bh-app-version
 * Name: BH App Version
 * Description: Displays standardized app version
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Component({
  selector: 'bh-app-version',
  templateUrl: './bh-app-version.component.html',
  styleUrls: ['./bh-app-version.component.scss'],
})
export class BhAppVersionComponent implements OnInit {
  @Input() contrast: 'high' | 'low' = 'high';
  @Input() opacity = 1;
  @Input() label = null;
  public env = environment;

  constructor() { }

  ngOnInit() {}

}
