import { FeedbackPage } from './../feedback/feedback.page';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { AboutPage } from './../about/about.page';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController, NavController, ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

/**
 * ID: bh-user-popover-page
 * Name: BH User Popover Page
 * Description: The popover used to display user-menu options.
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Component({
  selector: 'app-user-menu-popover',
  templateUrl: './user-menu-popover.page.html',
  styleUrls: ['./user-menu-popover.page.scss'],
})
export class UserMenuPopoverPage implements OnInit {
  env = environment;

  constructor(
    private authService: AuthService,
    private popoverCtrl: PopoverController,
    private navService: NavigationService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService
  ) { }

  ngOnInit() {
  }

  openPage(page) {
    this.analytics.clickEvent('user-menu-popover: open-' + page, '');

    switch (page) {
      case 'logout':
        this.authService.logout();
        break;

      case 'about':
        this.openAbout();
        break;

      case 'feedback':
        this.openFeedback();
        break;

      default:
        this.navService.navigateRoot(page);
        break;
    }
    this.popoverCtrl.dismiss();
  }

  async openAbout() {
    const modal = await this.modalCtrl.create({
      component: AboutPage,
      cssClass: 'wide-modal'
    });

    await modal.present();
  }

  async openFeedback() {
    const modal = await this.modalCtrl.create({
      component: FeedbackPage,
    });

    await modal.present();
  }


}
