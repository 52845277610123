import { Component, OnInit } from '@angular/core';
import { ManageUserEditorPage } from '../manage-user-editor/manage-user-editor.page';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { User, Users } from 'src/app/models/user';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { UsersService } from 'src/app/services/users/users.service';
import { isArray } from 'util';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.page.html',
  styleUrls: ['./manage-users.page.scss'],
})
export class ManageUsersPage implements OnInit {

  initUsers: User[] = [];
  users: User[] = [];
  searchTerm = '';
  isLoading = true;

  constructor(
    private usersService: UsersService,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private modalCtrl: ModalController
  ) { }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    try {
      this.isLoading = true;
      await firstValueFrom(this.usersService.getAll(true)).then( data => {
        console.log('res users', data);

        if (data && Array.isArray(data.users) && data.users.length > 0) {
          this.initUsers = data.users;
          console.log('loadData', this.initUsers);
          this.sortUsers();
          this.users = Object.assign([], this.initUsers);
        }
        this.isLoading = false;
      });
      return Promise.resolve(true);
      // this.classes = this.testClasses;
    } catch (err) {
      this.notifications.handleError(err, 'loadData');
      return Promise.reject();
    }
  }

  sortUsers() {
    this.initUsers.sort((a, b) => {
      const aName = a.lastName + ',' + a.firstName;
      const bName = b.lastName + ',' + b.firstName;
      if (aName.toLowerCase() < bName.toLowerCase()) return -1;
      if (aName.toLowerCase() > bName.toLowerCase()) return 1;
      return 0;
    });
  }

  async add() {
    this.analytics.clickEvent('Manage-Users > Add', '');
    const modal = await this.modalCtrl.create({
      component: ManageUserEditorPage,
      cssClass: 'edit-modal',
      componentProps: {
        editMode: 'new',
        allUsers: this.users
        // userId: user.id
      }
    });

    modal.onDidDismiss().then(async (data) => {
      if (data && data.data) {
        if (data.data.isSaved) {
          await this.loadData();
          this.search(this.searchTerm);
        } else if (data.data.dismiss) {
          this.modalCtrl.dismiss();
        }
      }
    });

    await modal.present();
  }



  async edit(user: User) {
    console.log('editing', user);
    this.analytics.clickEvent('Manage-Users > Edit', user.userId);
    const modal = await this.modalCtrl.create({
      component: ManageUserEditorPage,
      cssClass: 'edit-modal',
      componentProps: {
        editMode: 'edit',
        userId: user.id,
        user
      }
    });

    modal.onDidDismiss().then(async (data) => {
      if (data && data.data) {
        if (data.data.isSaved) {
          await this.loadData();
          this.search(this.searchTerm);
        } else if (data.data.dismiss) {
          this.modalCtrl.dismiss();
        }
      }
    });

    await modal.present();
  }

  search(ev) {
    this.searchTerm = (ev.detail) ? ev.detail.value : this.searchTerm;
    const qry = this.searchTerm.toLowerCase();
    if (qry) {
      for (const u of this.initUsers) {
        u.hide = u.userId.toLowerCase().indexOf(qry) === -1 &&
          u.firstName.toLowerCase().indexOf(qry) === -1 &&
          u.lastName.toLowerCase().indexOf(qry) === -1;
      }
    } else {
      for (const u of this.users) {
        u.hide = false;
      }
    }
    console.log('search', this.searchTerm, this.users.filter(u => !u.hide));
  }

  dismiss() {
    this.analytics.clickEvent('manage-user-list: dismiss', '');
    this.modalCtrl.dismiss();
  }

}
