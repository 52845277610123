import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { VerlockerData } from 'src/app/models/_core/verlocker-data';

@Injectable({
  providedIn: 'root'
})
export class VerlockerService {

  env = environment;
  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) { }

  loadAllVerlockerData() {
    const url = `${environment.apiUrl}/verlockerApps`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'verlocker-service.loadAllVerlockerData()' + url
        );
        return of(err);
      })
    );
  }

  getVerlockerDataForSingleApp(appId: string) {
    const url = `${environment.apiUrl}/verlockerData/${appId}`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'verlocker-service.getVerlockerDataForSingleApp()' + url
        );
        return of(err);
      })
    );
  }

  saveVerlocker(verLocker) {
    const url = `${environment.apiUrl}/saveVerlocker`;
    const body = verLocker;
    return this.http.post(url, body).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
      catchError((err) => {
        this.notifications.handleError(
          err,
          'verlocker-service.saveVerlocker()' + url
        );
        return of(err);
      })
    );
  }


}
