<div class="message-banner" [ngClass]="type">
  <div class="message-banner-icon" *ngIf="ionIcon">
    <ion-icon [name]="ionIcon" [color]="ionIconColor"></ion-icon>
  </div>
  <div class="message-banner-text">
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
