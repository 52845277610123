<ion-card
  ion-button
  href="javascript:void(0)"
  (click)="click()"
  *ngIf="layout === 'standard'"
  [ngStyle]="{ 'max-width': maxWidth }"
>
  <div
    class="card-image"
    [ngStyle]="{ 'background-image': 'url(' + this.heroImageUrl + ')' }"
    *ngIf="heroImageUrl"
  ></div>

  <ion-card-header *ngIf="title || subtitle">
    <ion-card-title *ngIf="title"
      ><strong [innerHtml]="title"></strong
    ></ion-card-title>
    <ion-card-subtitle
      *ngIf="subtitle"
      [innerHtml]="subtitle"
    ></ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ng-content #standardContent select="[standard-content]"></ng-content>
    <div class="social-toolbar" *ngIf="showSocialToolbar">
      <span>
        <ion-icon name="eye"></ion-icon>
        {{ views }}
      </span>
      <span>
        <ion-icon name="thumbs-up"></ion-icon>
        {{ likes }}
      </span>
      <span>
        <ion-icon name="chatbox-ellipses"></ion-icon>
        {{ comments }}
      </span>
      <span *ngIf="isFavorite">
        <ion-icon name="star" color="warning"></ion-icon>
        Following
      </span>
    </div>
  </ion-card-content>
  <!-- <img [src]="this.heroImageUrl" *ngIf="heroImageUrl" /> -->
</ion-card>

<ion-card
  ion-button
  href="javascript:void(0)"
  (click)="click()"
  class="custom-card"
  *ngIf="layout === 'app'"
  [ngStyle]="{ 'max-width': maxWidth }"
>
  <div
    class="card-image"
    [ngStyle]="{ 'background-image': 'url(' + this.heroImageUrl + ')' }"
    *ngIf="heroImageUrl"
  >
  <div class="card-image-inner">
    <div class="card-app-icon" [ngStyle]="{ 'background-image': 'url(' + this.heroImageUrl + ')' }">

    </div>
  </div>
</div>

  <!-- <ion-card-header *ngIf="title || subtitle">
    <ion-card-title *ngIf="title"
      ><strong [innerHtml]="title"></strong
    ></ion-card-title>
    <ion-card-subtitle
      *ngIf="subtitle"
      [innerHtml]="subtitle"
    ></ion-card-subtitle>
  </ion-card-header> -->
  <ion-card-content>
    <ng-content #app select="[app]"></ng-content>
    <!-- <div class="social-toolbar" *ngIf="showSocialToolbar">
      <span>
        <ion-icon name="eye"></ion-icon>
        {{ views }}
      </span>
      <span>
        <ion-icon name="thumbs-up"></ion-icon>
        {{ likes }}
      </span>
      <span>
        <ion-icon name="chatbox-ellipses"></ion-icon>
        {{ comments }}
      </span>
      <span *ngIf="isFavorite">
        <ion-icon name="star" color="warning"></ion-icon>
        Following
      </span>
    </div> -->
    <div class="app-info-container">
        <div class="app-info-section">
          <div class="title">
              {{appTitle}}
          </div>
          <div class="description">
            {{appDescription}}
          </div>
        </div>
          <!-- <ion-button color="tertiary">
            Get
          </ion-button> -->
    </div>
  </ion-card-content>
  <!-- <img [src]="this.heroImageUrl" *ngIf="heroImageUrl" /> -->
</ion-card>

<a
  ion-button
  href="javascript:void(0)"
  (click)="click()"
  *ngIf="layout === 'list'"
  [ngStyle]="{ 'max-width': maxWidth }"
  class="list-card ion-activatable ripple-parent"
>
  <ion-ripple-effect></ion-ripple-effect>
  <div
    class="image-container"
    *ngIf="heroImageUrl"
    [ngStyle]="{ 'background-image': 'url(' + this.heroImageUrl + ')' }"
  ></div>
  <div class="icon-container" *ngIf="ionIcon">
    <ion-icon [name]="ionIcon" [color]="ionIconColor"></ion-icon>
    <ion-badge color="danger" *ngIf="showIconBadge"></ion-badge>
  </div>
  <div class="card-container">
    <ion-card-header>
      <ion-card-title *ngIf="title"
        ><span [innerHtml]="title"></span
      ></ion-card-title>
      <ion-card-subtitle
        *ngIf="subtitle"
        [innerHtml]="subtitle"
      ></ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <ng-content #listContent select="[list-content]"></ng-content>
      <div class="social-toolbar" *ngIf="showSocialToolbar">
        <span>
          <ion-icon name="eye"></ion-icon>
          {{ views }}
        </span>
        <span>
          <ion-icon name="thumbs-up"></ion-icon>
          {{ likes }}
        </span>
        <span>
          <ion-icon name="chatbox-ellipses"></ion-icon>
          {{ comments }}
        </span>
        <span *ngIf="isFavorite">
          <ion-icon name="star" color="warning"></ion-icon>
          Following
        </span>
      </div>
    </ion-card-content>
  </div>
</a>

<a
  ion-button
  href="javascript:void(0)"
  (click)="click()"
  *ngIf="layout === 'hero'"
  class="compact-card ion-activatable ripple-parent"
  [ngClass]="{ 'no-bg': !this.heroImageUrl }"
  [ngStyle]="{
    'background-image': 'url(' + this.heroImageUrl + ')',
    'max-width': maxWidth
  }"
>
  <ion-ripple-effect></ion-ripple-effect>
  <div class="compact-card-inner">
    <div class="floating-header">
      <div class="title" [innerHtml]="title"></div>
      <div class="subtitle" [innerHtml]="subtitle"></div>
      <div class="social-toolbar" *ngIf="showSocialToolbar">
        <span>
          <ion-icon name="eye"></ion-icon>
          {{ views }}
        </span>
        <span>
          <ion-icon name="thumbs-up"></ion-icon>
          {{ likes }}
        </span>
        <span>
          <ion-icon name="chatbox-ellipses"></ion-icon>
          {{ comments }}
        </span>
        <span *ngIf="isFavorite">
          <ion-icon name="star" color="warning"></ion-icon>
          Following
        </span>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</a>

<a
  class="metric-card ion-activatable ripple-parent"
  href="javascript:void(0)"
  (click)="click()"
  *ngIf="layout === 'metric'"
  [ngStyle]="{ 'max-width': maxWidth }"
>
  <div class="metric-card-icon-wrapper" *ngIf="ionIcon">
    <ion-icon [name]="ionIcon" [color]="ionIconColor"></ion-icon>
  </div>
  <div class="metric-card-body">
    <h4>{{ title }}</h4>
    <p class="metric-description" *ngIf="subtitle">{{ subtitle }} &nbsp;</p>
    <div class="metric-value" *ngIf="metricValue !== null">
      {{ metricValue }} &nbsp;
    </div>
    <div class="metric-detail" *ngIf="metricValue !== null">
      {{ metricDetail }} &nbsp;
    </div>
    <div class="social-toolbar" *ngIf="showSocialToolbar">
      <span>
        <ion-icon name="eye"></ion-icon>
        {{ views }}
      </span>
      <span>
        <ion-icon name="thumbs-up"></ion-icon>
        {{ likes }}
      </span>
      <span>
        <ion-icon name="chatbox-ellipses"></ion-icon>
        {{ comments }}
      </span>
      <span *ngIf="isFavorite">
        <ion-icon name="star" color="warning"></ion-icon>
        Following
      </span>
    </div>
    <ng-content #metricContent select="[metric-content]"></ng-content>
  </div>
  <ion-ripple-effect></ion-ripple-effect>
</a>
