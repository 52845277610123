<ion-menu
  contentId="main-content"
  (ionWillClose)="dismissMenu()"
  side="end"
  menuId="sideMenu"
>
  <ion-header>
    <ion-toolbar>
      <ion-icon name="cog" slot="start"></ion-icon>
      <ion-title>
        <div class="wrap-text">Manage BH AppStore</div>
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <ion-list-header>Manage</ion-list-header>
      <ion-item
        button
        href="javascript:void(0)"
        (click)="signIn()"
        *ngIf="!authUser || !authUser?.userId && isMobile"
      >
      <ion-icon name="log-in-outline"></ion-icon>
        <ion-label>Log In</ion-label>
      </ion-item>
      <ion-item
        button
        (click)="manageUsers()"
        *ngIf="
          authUser &&
          (authUser?.role === 'ADMIN' || authUser?.role === 'SYS_ADMIN')
        "
      >
        <ion-icon name="people-outline" slot="start"></ion-icon>
        <ion-label>Users</ion-label>
      </ion-item>
      <ion-item
        button
        (click)="manageAppAccess()"
        *ngIf="
          authUser &&
          (authUser?.role === 'ADMIN' || authUser?.role === 'SYS_ADMIN')
        "
        >
        <ion-icon name="key-outline" slot="start"></ion-icon>
        <ion-label>App Access</ion-label>
      </ion-item>
      <ion-item
        button
        (click)="manageVerlocker()"
        *ngIf="
          authUser &&
          (authUser?.role === 'ADMIN' || authUser?.role === 'SYS_ADMIN')
        "
      >
        <ion-icon name="lock-closed-outline" slot="start"></ion-icon>
        <ion-label>App Versions</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>
<ion-app id="main-content">
  <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>
</ion-app>
<div class="loader-backdrop" [ngClass]="{ show: isLoading }">
  <div class="spinner-wrapper">
    <div class="spinner-background">
      <ion-spinner></ion-spinner>
      <div class="loading-message" *ngIf="loadingMessage">
        {{ loadingMessage }}
      </div>
    </div>
  </div>
</div>
