import { BhDataGridColumnDefinition } from '../../../models/_core/bh-data-grid-column-definition';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

/**
 * ID: bh-grid
 * Name: BH Grid
 * Description: A responsive data grid that shows either mat-table or bh-card to present data depending on viewport
 * Version: 3
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-06-23 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented proper date sorting
 * 2022-05-09 - MW - v3: Fixed issue with timing of sort assignment, updated name from bh-grid to bh-data-grid
 */
@Component({
  selector: 'bh-data-grid',
  templateUrl: './bh-data-grid.component.html',
  styleUrls: ['./bh-data-grid.component.scss'],
})
export class BhDataGridComponent implements OnChanges {
  @Input() columnDefinitions: BhDataGridColumnDefinition[] = [];
  @Input() data: any[] = [];
  @Output() sortEvent = new EventEmitter<any>();
  @Output() clickEvent = new EventEmitter<any>();
  @ViewChild(MatSort) sort: MatSort;
  parsedData: any[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];

  constructor() { }

  @ViewChild(MatSort, {static: false}) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('columnDefinitions' in changes && this.columnDefinitions) {
      this.setColumnDefinitions();
    }

    if ('data' in changes && this.data) {
      let i = 0;
      this.parsedData = [];
      for (const dataItem of this.data) {
        const parsedItem = { bhGridRowIndex: i };
        for (const colDef of this.columnDefinitions) {
          let dataElement = dataItem[colDef.fieldName];
          // Check if date and format for sorting
          if (colDef.isDate && colDef.dateFormat) {
            const dataElementMoment = moment(dataElement, colDef.dateFormat);
            // Check for successful date parse
            if (dataElementMoment.isValid()) {
              dataElement = dataElementMoment.format('YYYY-MM-DD HH:mm:ss');
            } else {
              colDef.isDate = false;
            }
          } else {
            colDef.isDate = false;
          }
          parsedItem[colDef.fieldName] = dataElement;
        }
        this.parsedData.push(parsedItem);
        i += 1;
      }
      this.setDataSource();
    }
  }

  setColumnDefinitions() {
    this.displayedColumns = [];
    for (const cd of this.columnDefinitions) {
      if (cd.showColumn) {
        this.displayedColumns.push(cd.fieldName);
      }
    }
  }

  setDataSource() {
    this.dataSource = new MatTableDataSource<any>(this.parsedData);
    this.dataSource.sort = this.sort;
  }

  selectRow(dataItem) {
    this.clickEvent.emit(dataItem);
  }

  getDataItemIcon(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const icon = this.columnDefinitions[0].ionIcon;
      return icon ? icon : null;
    } else {
      return null;
    }
  }

  checkDataItemBadge(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const hasIconBadge = this.columnDefinitions[0].hasIconBadge;
      const iconBadgeArgField = this.columnDefinitions[0].iconBadgeArgField;
      const iconBadgeArgValue = this.columnDefinitions[0].iconBadgeArgValue;
      return hasIconBadge && dataItem[iconBadgeArgField] ? dataItem[iconBadgeArgField] ===  iconBadgeArgValue : null;
    } else {
      return null;
    }

  }

  getDataItemTitle(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const key = this.columnDefinitions[0].fieldName;
      return dataItem[key] ? dataItem[key] : null;
    } else {
      return null;
    }
  }

  getOtherDataItemProperties(dataItem) {
    if (this.columnDefinitions.length > 1) {
      let innerHtml = '<div class="font-size-small">';
      let index = 0;
      for (const cd of this.columnDefinitions) {
        if (Object.prototype.hasOwnProperty.call(dataItem, cd.fieldName) && index > 0) {
          const value = dataItem[cd.fieldName];
          // const iconName = cd.ionIcon ? cd.ionIcon : null;
          // const iconColor = cd.ionIconColor ? cd.ionIconColor : null;
          // const icon = (cd.showIconArg === dataItem[cd.fieldName] || cd.alwaysShowIcon) ?
          //   '...<ion-icon name="' + iconName + '" color="' + iconColor + '"></ion-icon>...' : '';
          // value = icon && cd.showIconOnly ?
          //   icon : icon ? value + icon : value;
          innerHtml += cd.columnLabel + ': <strong>' + value + '</strong><br>';
        }
        index += 1;
      }
      const key = this.columnDefinitions[1].fieldName;
      return index > 0 ? innerHtml + '</div>' : null;
    } else {
      return null;
    }
  }

  sortChanged(ev) {
    this.sortEvent.emit(ev);
  }

}
