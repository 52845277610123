<ion-header class="modal-header mobile-only">
  <ion-toolbar>
    <ion-title>
      <div class="icon-and-title">
        <ion-icon name="person-outline"></ion-icon>
        {{ modalTitle }}
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="two-panel">
    <div class="information-panel desktop-only">
      <h1>{{ modalTitle }}</h1>
      <p>Edit the properties of this user.</p>
    </div>
    <div class="form-panel">
      <div
      class="form-panel-inner"
      [ngClass]="{'with-header': editMode === 'edit'}"
      >
      <div
      class="form-panel-header desktop-only-flex"
      *ngIf="editMode === 'edit'"
      ></div>
      <a
      href="javascript:void(0)"
      (click)="dismiss()"
      class="close-button desktop-only"
      >
      <ion-icon name="close-outline"></ion-icon>
    </a>

    <ng-container>
          <h3>Record Information</h3>
          <form [formGroup]="form1">
              <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="userId"
              placeholder=""
              label="User ID"
              position="stacked"
              [value]=""
              [readonly]="editMode === 'edit'"
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

            <ng-container
              *ngIf="form1.controls.userId.value"
            >
              <div class="check-status" *ngIf="checkStatus === 'checking'">
                <ion-spinner name="crescent" size="small"></ion-spinner>
                Checking for existing user
              </div>

              <div class="check-status success" *ngIf="checkStatus === 'new'">
                <ion-icon name="checkmark-circle-outline"></ion-icon>
                This user an be added.
              </div>

              <div
                class="check-status warning"
                *ngIf="checkStatus === 'exists'"
              >
                <ion-icon name="alert-circle-outline"></ion-icon>
                <span>This user exists and cannot be recreated.</span>
              </div>
            </ng-container>


              <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="name"
              placeholder=""
              label="First Name & Last Name"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

              <bh-input
              [formGroup]="form1"
              type="ion-select"
              formControlName="role"
              placeholder=""
              label="Role"
              position="stacked"
              [selectOptions]="roleOptions"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>

          </form>
        </ng-container>

        <div class="form-panel-footer desktop-only">
          <!-- <div class="form-panel-footer-spacer"></div> -->
          <div class="form-panel-footer-inner">
            <ion-button
              [disabled]="!form1.valid"
              (click)="save()"
              >Save</ion-button
            >
            <ion-button fill="outline" (click)="dismiss()">Discard</ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="mobile-only" *ngIf="formReady">
  <ion-button [disabled]="!form1.valid" (click)="save()"
    >Save</ion-button
  >
</ion-footer>
