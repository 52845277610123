import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription, firstValueFrom } from 'rxjs';
import { SelectOption } from 'src/app/models/_core/select-option';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-manage-user-editor',
  templateUrl: './manage-user-editor.page.html',
  styleUrls: ['./manage-user-editor.page.scss'],
})
export class ManageUserEditorPage implements OnInit {
  @Input() editMode: 'new' | 'edit' = 'new';
  //@Input() userId: string;
  @Input() selectedUser: User;
  @Input() allUsers: User[];
  user: User;
  initUserName = '';
  modalTitle = '';
  formReady = false;
  // isChecking = false;
  checkStatus: 'not-checked' | 'checking' | 'new' | 'exists' = 'not-checked';
  checkTimer = null;
  authUser: User;

  form1: FormGroup = this.formBuilder.group({
    name: [null, Validators.required],
    userId: [null, Validators.required],
    role: [null, Validators.required],
  });
  subscriptions: Subscription[] = [];
  showErrorMessage = false;
  submitAttempted = false;
  validationMessages = {
    name: [{ type: 'required', message: 'Name is required.' }],
    userId: [{ type: 'required', message: 'User ID is required.' }],
    role: [{ type: 'required', message: 'Role is required.' }],
  };

  roleOptions: SelectOption[] = [
    {
      label: 'ADMIN',
      value: 'ADMIN'
    },
    {
      label: 'SUID',
      value: 'SUID'
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notifications: NotificationsService,
  ) { }

  ngOnInit() {
    console.log('user', this.user, 'all users', this.allUsers);

    this.authUser = this.authService.getAuthUser();
    this.setEditMode();
    this.subscribeToControls();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
      s = null;
    });
  }

  setEditMode() {
    if (this.editMode === 'edit' && this.user) {
      this.modalTitle = 'Edit User';
      this.loadData();
    } else {
      this.modalTitle = 'New User';
      this.user = {};
    }
  }

  isNameValid() {
    return (
      (this.editMode === 'edit' && this.checkStatus !== 'new' && this.initUserName === this.form1.controls.name.value.toLowerCase()) ||
      (this.checkStatus === 'new')
    );
  }

  subscribeToControls() {
    if (this.subscriptions.length === 0) {
      this.subscriptions.push(
        this.form1.controls.userId.valueChanges.subscribe(async val => {
            this.checkForExistingUser(val);
        })
      )
    }
  }

  async loadData() {
    try {
      if (this.user) {
        this.form1.controls.name.setValue(this.user.name);
        this.form1.controls.userId.setValue(this.user.userId);
        this.form1.controls.role.setValue(this.user.role);
      }
    } catch (err) {
      this.modalCtrl.dismiss();
      this.notifications.handleError(err, 'manage-user-editor.loadData');
    }
  }

  async checkForExistingUser(userId: string) {
    if (this.checkTimer) {
      clearTimeout(this.checkTimer);
      this.checkTimer = null;
    }
    if (userId && !this.checkTimer) {
      this.checkTimer = setTimeout(async () => {
        this.formReady = false;
        this.checkStatus = 'checking';
        try {
          const foundUser = this.allUsers.find(user => user.userId.toLowerCase() === userId.toLowerCase());
          //const res = await firstValueFrom(this.userService.getById(userId));
          console.log('Loading user', foundUser);
          if (foundUser && foundUser.userId !== '' && this.checkString(foundUser.userId)) {
            this.checkStatus = 'exists';
            this.formReady = false;
          }
          else if (this.checkString(userId)){
            this.checkStatus = 'new';
            this.formReady = true;
          }
        } catch (err) {
          // this.modalCtrl.dismiss();
          this.notifications.handleError(err, 'manage-user-editor.checkForExistingUser');
        }
      }, 500);
    } else {
      this.checkStatus = 'not-checked';
    }

  }

  checkString(input: string): boolean {
    const regexPattern = /^[A-Za-z]{2}\d{2}.{3,}$/;
    return regexPattern.test(input);
  }

  // async impersonate() {
  //   try {
  //     const res = await firstValueFrom(this.userService.impersonate(this.user.id));
  //     this.analytics.clickEvent('manage-user-editor: save', '');
  //     this.authService.handleLoginResponse(res);
  //     this.notifications.showToast('Impersonating ' + this.user.firstName + ' ' + this.user.lastName + ' ('+ this.user.userName +')');
  //     // this.modalCtrl.dismiss({ savedTag: this.tag });
  //     this.modalCtrl.dismiss({ dismiss: true });
  //   } catch (err) {
  //     this.notifications.handleError(err, 'manage-user-editor.impersonate');
  //   }
  // }

  async save() {
    // console.log('saving: ', this.form1, this.user, this.editMode);
    this.submitAttempted = true;
    if (this.form1.valid) {
      try {
        // Update container properties

        // this.user.firstName = this.form1.controls.firstName.value;
        // this.user.lastName = this.form1.controls.lastName.value;
        // this.user.isActive = this.form1.controls.isActive.value;
        // Save container
        if (this.editMode === 'edit' && this.user.userId) {
          this.user.name = this.convertFullNameToTitleCase(this.form1.controls.name.value.toLowerCase());
          this.user.userId = this.form1.controls.userId.value.toLowerCase();
          this.user.role = this.form1.controls.role.value.toUpperCase();
          const res = await firstValueFrom(this.userService.create(this.user));
        } else {
          const newUser: User = {
            userId:  this.form1.controls.userId.value.toLowerCase(),
            name:  this.convertFullNameToTitleCase(this.form1.controls.name.value.toLowerCase()),
            role: this.form1.controls.role.value.toUpperCase(),
          }
          const res = await firstValueFrom(this.userService.create(newUser));
          newUser.userId = res.id;
        }
        this.analytics.clickEvent('manage-tag-editor: save', '');
        this.notifications.showToast('Saved successfully.');
        // this.modalCtrl.dismiss({ savedTag: this.tag });
        this.modalCtrl.dismiss({ isSaved: true });
      } catch (err) {
        this.notifications.handleError(err, 'manage-tag-editor.save');
      }
    }
  }

  convertFullNameToTitleCase(fullName: string): string {
    // Split the full name into an array of words
    const words = fullName.toLowerCase().split(' ');

    // Iterate through the words and capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back together to form the title case full name
    fullName = words.join(' ');
    return fullName;
  }

  dismiss() {
    this.analytics.clickEvent('manage-tag-editor: dismiss', '');
    this.modalCtrl.dismiss();
  }

}
