<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title class="text-align-center">{{ label }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="done()">Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-textarea
    [placeholder]="placeholder"
    [readonly]="readonly"
    [maxlength]="maxlength"
    [(ngModel)]="newValue"
    autofocus
    [id]="'texteditor-ion-textarea-' + formControlName"
    class="fullpage-textarea"
  ></ion-textarea>
</ion-content>
