import { NotificationsService } from '../notifications/notifications.service';
import { HelperUtilitiesService } from '../helper-utilities/helper-utilities.service';
import { Platform } from '@ionic/angular';
import { environment } from '../../../../environments/environment.dev';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
// import { reject } from 'q';

/**
 * ID: bh-storage-service
 * Name: BH Storage Service
 * Description: Service used for managing local storage on the device
 * Version: 3
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2022-04-26 - MW - v2: Upgraded to Ionic 6/storage-angular
 * 2022-05-26 - MW - v3: Fixed issue with initializing storage
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    public storage: Storage,
    public platform: Platform,
    public helpers: HelperUtilitiesService,
    public notificationsService: NotificationsService
  ) {
    this.storage.create();
  }

  // ******** Native Unsecure Storage **********

  /***
   * Gets data from native unsecured storage
   * @param key Key reference name
   */
  async getData(key): Promise<any> {
    return new Promise(async resolve => {
      await this.storage.get(key).then(
        data => {
          // console.log('storage > getData', data);
          if (data) {
            resolve(data.property);
          } else {
            resolve({});
          }
        },
        (err) => {
          this.notificationsService.handleError(err, 'storage-service.getData() => nativeStorage.getItem() ', key);
          if (err.code === 2) {
            resolve(null);
          }
          Promise.reject(err);
        }
      );
    });
  }

  /***
   * Saves data to native unsecured storage
   * @param key Key reference name
   * @param value Value of the key
   */
  async saveData(key, value): Promise<any> {
    return new Promise(async resolve => {
      await this.storage
        .set(key, { property: value })
        .then(
          () => {
            console.log('Stored item!', key, value);
            resolve(true);
          },
          err => {
            this.notificationsService.handleError(err, 'storage-service.saveData() => nativeStorage.setItem() ', key);
            Promise.reject(err);
          }
        );
    });
  }

  /***
   * Removes key value pair from native unsecured storage
   * @param key Key reference name
   */
  async removeData(key): Promise<boolean> {
    return new Promise(async resolve => {
      await this.storage
        .remove(key)
        .then(
          () => {
            resolve(true);
            // console.log('Removed item!');
          },
          (err) => {
            this.notificationsService.handleError(err, 'storage-service.removeData() => nativeStorage.remove() ', key);
            Promise.reject(err);
          }
        );
    });
  }

}
