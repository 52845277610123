import { Keyboard } from '@capacitor/keyboard';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Component, HostListener, OnInit } from '@angular/core';
import { BhAnalyticsService } from './services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from './services/_core/notifications/notifications.service';

import { UserDeviceService } from './services/_core/user-device/user-device.service';
import { VerlockerService } from './services/_core/verlocker/verlocker.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { AuthService } from './services/_core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ThemeOption } from './models/_core/theme-option';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ManageAppsPage } from './pages/manage-apps/manage-apps.page';
import { ManageUsersPage } from './pages/manage-users/manage-users.page';
import { ManageVerlockerPage } from './pages/manage-verlocker/manage-verlocker.page';
import { ManageAppAccessPage } from './pages/manage-app-access/manage-app-access.page';
import { User } from './models/user';
import { UserState } from './models/_core/user-state';
import { StorageService } from './services/_core/storage/storage.service';
import { NavigationService } from './services/navigation/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
/**
 * ID: app-component
 * Name: App-Component
 * Description: Root App Component that handles root-level app-wide logic.
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2022-05-26 - MW - v2: Integraeted dark mode preference
 */
export class AppComponent implements OnInit {
  env = environment;
  prefersDark = false;
  theme: ThemeOption = 'M';
  theme2 = false;
  subs: Subscription[] = [];
  updateInterval = null;
  displayingVerlocker = false;
  loadingSub: Subscription = null;
  isLoading = false;
  loadingMessage = '';
  enableDarkMode = false;
  isMobile = window.innerWidth <= 768;
  authUser: User;
  userState: UserState
  subscriptions: Subscription[] = [];

  constructor(
    private deviceService: UserDeviceService,
    private platform: Platform,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private verlockerService: VerlockerService,
    private authService: AuthService,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private storageService: StorageService,
    public navService: NavigationService,
  ) {
    this.initializeApp();
    this.subscribeToLoader();
    this.checkVersion();
    this.subscriptions.push(
      this.authService.authUser.subscribe(val => this.authUser = val)
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.deviceService.loadDeviceProperties();
  }

  async ngOnInit() {
    this.listenForThemePreference();
    this.subscribeToUserDevice();
    this.subscribeToUserState();
    if(environment.preventSearchIndexing) {
      this.addNoIndexTag();
    }
  }

  async loadUserStateFromStorage() {
   this.userState = await this.storageService.getData('userState');
   if(this.userState.theme === 'L') {
     this.theme2 = false;
   }
   else if(this.userState.theme === 'D') {
    this.theme2 = true;
   }
   this.authService.userState.theme = this.userState.theme;
   this.authService.saveUserStateToStorage();
  }

  addNoIndexTag() {
    const metaTag: HTMLMetaElement = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex, nofollow';
    document.head.appendChild(metaTag);
  }


  listenForThemePreference() {
    this.prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.setTheme();
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (mediaQuery) => {
        // console.log('listening for theme pref', mediaQuery);
        const device = this.deviceService.deviceSubject.getValue();
        device.prefersDark = mediaQuery.matches;
        this.prefersDark = device.prefersDark;
        this.deviceService.deviceSubject.next(device);
        this.setTheme();
      });
  }


  subscribeToUserState() {
    this.subs.push(
      this.authService.userStateSubject.subscribe(us => {
        console.log('UserState updated', us);
        this.theme = us.theme;
        this.setTheme();
      })
    );
  }

  subscribeToUserDevice() {
    this.subs.push(
      this.deviceService.deviceSubject.subscribe(d => {
        console.log('UserDevice updated', d);
        this.prefersDark = d.prefersDark;
        this.setTheme();
      })
    );
  }

  setTheme() {
    let currentTheme: ThemeOption;
    // Check if theme is user-defined
    if (this.env.theme === 'user') {
      // Check if user is not matching OS
      if (this.theme !== undefined && this.theme !== 'M') {
        console.log('found theme', this.theme);
        switch (this.theme) {
          case 'D':
            // Set to dark
            document.body.classList.add('dark');
            currentTheme = 'D';
            break;

          default:
            // Set to light
            document.body.classList.remove('dark');
            currentTheme = 'L';
            break;
        }
      } else if (this.prefersDark) {
        // console.log('Setting dark theme');
        document.body.classList.add('dark');
        currentTheme = 'D';
      } else {
        // console.log('Setting light theme');
        document.body.classList.remove('dark');
        currentTheme = 'L';
      }
    } else {
      // Theme is defined by environment
      if (this.env.theme === 'dark') {
        document.body.classList.add('dark');
        currentTheme = 'D';
      } else {
        document.body.classList.remove('dark');
        currentTheme = 'L';
      }
    }
    // console.log('Setting theme', this.env.theme, this.theme, this.prefersDark);
    this.authService.setTheme(currentTheme);
  }

  async initializeApp() {
    this.loadUserStateFromStorage();
    // await this.storage.create();
    this.platform.ready().then(() => {
      this.analytics.initAnalytics();
      this.initNativeFeatures();
    });
  }

  async initNativeFeatures() {
    if (this.platform.is('mobile')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      // this.statusBar.styleDefault();
      await StatusBar.hide();
    }
  }

  subscribeToLoader() {
    this.loadingSub = this.notifications.isLoadingBehaviorSubject.subscribe(val => {
      this.isLoading = val.isLoading;
      this.loadingMessage = val.message;
    });
  }


  async manageApps() {
    this.menuCtrl.close();
    const topModal: HTMLIonModalElement = await this.modalCtrl.getTop();
    if (topModal) {
      topModal.dismiss();
    }
    const modal = await this.modalCtrl.create({
      component: ManageAppsPage,
      cssClass: 'edit-modal',
      componentProps: {}
    });
    await modal.present();
  }

  async manageUsers() {
    this.menuCtrl.close();
    const modal = await this.modalCtrl.create({
      component: ManageUsersPage,
      cssClass: 'edit-modal',
      componentProps: {
      }
    });

    modal.onDidDismiss().then((data) => {
    });

    await modal.present();
  }

  async manageAppAccess() {
    this.menuCtrl.close();
    const modal = await this.modalCtrl.create({
      component: ManageAppAccessPage,
      cssClass: 'edit-modal',
      componentProps: {
      }
    });

    modal.onDidDismiss().then((data) => {
    });

    await modal.present();
  }

  async manageVerlocker() {
    this.menuCtrl.close();
    const modal = await this.modalCtrl.create({
      component: ManageVerlockerPage,
      cssClass: 'edit-modal-2',
      componentProps: {
      }
    });

    modal.onDidDismiss().then((data) => {
    });

    await modal.present();
  }

  dismissMenu() {
    this.authService.toggleMenu();
  }


  checkVersion() {
    if (!this.updateInterval) {
      firstValueFrom(this.verlockerService.checkVersion())
      this.updateInterval = setInterval(() => {
        if (!this.verlockerService.displayingVerlocker) {
          firstValueFrom(this.verlockerService.checkVersion());
        } else {
        }
      }, 120000);
    }
  }

  saveColorPreferences() {
    let theme: ThemeOption = 'M';
    if(this.theme2) {
        theme = 'D';
    }
    else {
      theme = 'L';
    }
    this.authService.userState.theme = theme;
    // this.authService.userState.useBiometrics = this.useBiometrics;
    // if (!this.useBiometrics) {
    //   this.authService.userState.passwordData = null;
    // }
    this.authService.saveUserStateToStorage();

  }

  savePreferences() {
    this.authService.userState.theme = this.theme;
    this.authService.saveUserStateToStorage();
  }

  signIn() {
    this.analytics.clickEvent('Header > Sign-in', '');
    this.menuCtrl.close();
    this.navService.navigateForward('/login');
  }


}
