<ion-content style="z-index: 99999" class="ion-padding">
  <ng-container *ngIf="currentView === 'prompt'">
    <!-- Requested alert -->
    <div
      *ngIf="verlockerData?.verstatus === 'REQUESTED'"
      class="margin-16 margin-top-32"
    >
      <div class="alert-icon">
        <ion-icon name="alert-circle-outline" color="warning"></ion-icon>
      </div>
      <h1 class="margin-top-0 margin-bottom-32 text-align-center">
        Update available
      </h1>
      <p class="text-align-center margin-bottom-48">
        Update this app to get the newest features, fixes, and best
        experience!
      </p>
      <div class="margin-bottom-48 text-align-center">
        <h3>What's new in version {{ verlockerData.latest_version }}?</h3>
        <p *ngIf="verlockerData.update_description">
          {{ verlockerData.update_description }}
        </p>
        <p *ngIf="!verlockerData.update_description">
          General fixes and improvements
        </p>
      </div>
    </div>

    <!-- Required alert -->
    <div
      *ngIf="verlockerData?.verstatus === 'REQUIRED' || verlockerData?.verstatus === 'EXPIRED'"
      class="margin-16 margin-top-32"
    >
      <div class="alert-icon">
        <ion-icon name="alert-circle-outline" color="danger"></ion-icon>
      </div>
      <h1
        class="
          margin-top-0 margin-bottom-32
          font-color-danger
          text-align-center
        "
      >
        Update required
      </h1>
      <p class="text-align-center margin-bottom-48">
        To continue using this app, you must update to the latest version, {{
        verlockerData?.latest_version }}.
        <span *ngIf="!this.helpers.isNotBrowser()">
          If you are using a web browser and are unable to get past this prompt,
          try clearing your browser cache and refresh this page.
        </span>
      </p>
      <div class="margin-bottom-48 text-align-center">
        <h3>What's new in version {{ verlockerData?.latest_version }}?</h3>
        <p *ngIf="verlockerData?.update_description">
          {{ verlockerData?.update_description }}
        </p>
        <p *ngIf="!verlockerData?.update_description">
          This update contains general fixes and improvements.
        </p>
      </div>
    </div>

    <div class="button-container margin-bottom-16 text-align-center">
      <ion-button
        (click)="update()"
        class="margin-top-16 standard-cta"
        shape="round"
        >Update now</ion-button
      >

      <ion-button
        (click)="cancel()"
        class="margin-top-16 standard-cta thin-outline"
        fill="outline"
        *ngIf="verlockerData?.verstatus === 'REQUESTED'"
        shape="round"
        expand="block"
        >Not right now</ion-button
      >
    </div>
  </ng-container>

  <ng-container *ngIf="currentView === 'downloading'">
    <div class="margin-top-32 alert-icon">
      <!-- <ion-icon name="cloud-download-outline" color="success"></ion-icon> -->
      <bh-spinner color="success"></bh-spinner>
    </div>
    <h1
      class="margin-top-32 margin-bottom-32 text-align-center font-color-success"
      *ngIf="osPlatform !== 'web'"
    >
      Downloading update
    </h1>
    <h1
      class="margin-top-32 margin-bottom-32 text-align-center font-color-success"
      *ngIf="osPlatform === 'web'"
    >
      Refreshing...
    </h1>

    <!-- iOS Instructions -->
    <p class="text-align-center margin-bottom-48" *ngIf="osPlatform === 'ios'">
      iPhone and iPad users, you should be prompted to install the new version
      of CaseShare {{ env.appDescription }}. After installing, the new version
      will download in the background and automatically close this old version.
      Once closed, relaunch the app. If this app doesn't close, try downloading
      again or use the 'Get help' button below.
    </p>

    <!-- Android Instructions -->
    <p
      class="text-align-center margin-bottom-48"
      *ngIf="osPlatform === 'android'"
    >
      Android users, the new version of {{ env.appDescription }} will download
      via your mobile device's web browser (e.g. Google Chrome). In your web
      browser, tap the newly downloaded APK file to start your update. Tap
      'Install' when prompted.
    </p>

    <!-- Web Instructions -->
    <p class="text-align-center margin-bottom-48" *ngIf="osPlatform === 'web'">
      Attempting to refresh your page... please wait.
    </p>

    <div class="button-container margin-bottom-16 text-align-center" *ngIf="updateAttempted">
      <h4 class="text-align-center">Update not working?</h4>
      <ion-button
        (click)="update()"
        class="margin-top-16 standard-cta"
        shape="round"
        >Try again</ion-button
      >

      <ion-button
        (click)="cancel()"
        class="margin-top-16 standard-cta thin-outline"
        fill="outline"
        *ngIf="verlockerData?.verstatus === 'REQUESTED'"
        shape="round"
        expand="block"
        >Not right now</ion-button
      >

      <ion-button
        (click)="getHelp()"
        class="margin-top-16 standard-cta thin-outline"
        fill="outline"
        shape="round"
        expand="block"
        >Get help</ion-button
      >
    </div>
  </ng-container>

  <div class="margin-16" *ngIf="currentView === 'prompt'">
    <bh-app-version label="You are currently using version: "></bh-app-version>
  </div>
  <div class="footer-spacer"></div>
</ion-content>
