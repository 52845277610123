<ion-header class="modal-header">
  <ion-toolbar>
    <ion-title *ngIf="!isLoading && displayMode === 'all-apps'">Manage App Access</ion-title>
    <ion-title *ngIf="displayMode === 'access'">Manage Access For {{titleAccess}}</ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="search-toolbar" *ngIf="displayMode === 'all-apps'">
    <ion-searchbar
      mode="ios"
      placeholder="Search app..."
      (ionInput)="search($event)"
      [debounce]="200"
    ></ion-searchbar>
  </div>
  <div class="action-container">
    <a href="javascript:void(0)" class="back-link" (click)="back()" *ngIf="displayMode === 'access'">
      <ion-icon name="arrow-back-circle-outline"></ion-icon>
      Back to All
    </a>
    <div class="add-button-wrapper" *ngIf="displayMode === 'access'">
      <ion-button fill="solid" (click)="addAccess()">
        Grant New Access
      </ion-button>
    </div>
  </div>

</ion-header>
<ion-content class="ion-padding">
  <ng-container>
    <div class="list-actions-container" *ngIf="!isLoading && displayMode === 'all-apps'">
      <div class="chip-container">
        <ion-chip *ngIf="sorting || filtering">
          <ion-label>Remove filters</ion-label>
          <ion-icon name="close-circle" (click)="removeFilters()"></ion-icon>
        </ion-chip>
        <!-- <ion-chip *ngIf="filtering">
          <ion-avatar>
            <ion-icon name="person-circle-outline"></ion-icon>
          </ion-avatar>
          <ion-label>{{filteringText | titlecase}}</ion-label>
          <ion-icon name="close-circle" (click)="removeFiltering()"></ion-icon>
        </ion-chip> -->
      </div>
      <ion-item>
        <!-- <ion-icon name="arrow-up-outline" *ngIf="sortOrder === 'ascending'"></ion-icon>
        <ion-icon name="arrow-down-outline" *ngIf="sortOrder === 'descending'"></ion-icon> -->
        <ion-icon name="funnel-outline"></ion-icon>
        <!-- <ion-label class="ion-text-wrap">{{sortOrder | titlecase}}</ion-label> -->
        <ion-select [(ngModel)]="sortOrder" (ionChange)="saveSort()" interface="popover" value="ascending">
          <ion-select-option value="ascending">Ascending</ion-select-option>
          <ion-select-option value="descending">Descending</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-icon name="filter-outline"></ion-icon>
        <!-- <ion-label class="ion-text-wrap">{{filterValue}}</ion-label> -->
        <ion-select [(ngModel)]="filterValue" (ionChange)="saveFilter()" interface="popover" value="">
          <ion-select-option value="employee">Employee</ion-select-option>
          <ion-select-option value="patient">Patient</ion-select-option>
          <ion-select-option value="partner">Partner</ion-select-option>
        </ion-select>
      </ion-item>
    </div>
  </ng-container>
  <bh-spinner
    type="skeleton"
    [skeletonItemCount]="10"
    *ngIf="isLoading"
  ></bh-spinner>
  <ion-list *ngIf="!isLoading && displayMode === 'all-apps'">
    <ng-container *ngFor="let a of appData">
      <ion-item button (click)="selectApp(a)">
        <ion-icon name="laptop-outline"></ion-icon>
        <ion-label>
          <div class="details" style="font-weight: bold;">{{ a?.name}} </div>
          <div class="details">{{ a?.appId }}</div>
        </ion-label>
        <ion-badge [color]="pickColor(a)">{{a.appTypeText}}</ion-badge>
      </ion-item>
    </ng-container>
    <p *ngIf="!appData || appData.length === 0">Apps will display here.</p>
  </ion-list>
  <ion-list *ngIf="displayMode === 'access'">
    <ng-container>
      <ion-item lines="none" class="table-header">
        <!-- <ion-label class="ion-text-start">App</ion-label> -->
        <ion-label class="ion-text-start">EN #</ion-label>
        <ion-label class="ion-text-center" style="padding-left: 12px">User ID</ion-label>
        <ion-label class="ion-text-end">Update Date</ion-label>
      </ion-item>
      <ng-container *ngFor="let ad of appAccessData">
        <ion-item button (click)="editAccess(ad)">
          <!-- <ion-icon name="laptop-outline"></ion-icon> -->
          <!-- <ion-label>
            <div class="ion-text-start">{{ ad?.appId}} </div>
          </ion-label> -->
          <ion-label>
            <div class="ion-text-start">{{ ad?.en}} </div>
            <!-- <div class="details">{{  }}</div> -->
          </ion-label>
          <ion-label>
            <div class="ion-text-center" style="padding-left: 12px">{{ ad?.userId}} </div>
            <!-- <div class="details">{{  }}</div> -->
          </ion-label>
          <ion-label>
            <div class="ion-text-end">{{ ad?.updateDate}} </div>
            <!-- <div class="details">{{  }}</div> -->
          </ion-label>
          <!-- <ion-badge [color]="pickColor(a)">{{a.appTypeText}}</ion-badge> -->
        </ion-item>
      </ng-container>
    </ng-container>
    <p *ngIf="!appAccessData || appAccessData.length === 0">App Access will display here.</p>
  </ion-list>
</ion-content>
