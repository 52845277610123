import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { UserState } from './../../../models/_core/user-state';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { StorageService } from './../../../services/_core/storage/storage.service';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

/**
 * ID: bh-about-page
 * Name: BH About Page
 * Description: An informative page that contains information on how to use the app.
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState firstTimeViewed
 */
@Component({
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss'],
})
export class AboutPage implements OnInit {
  env = environment;

  constructor(
    private modalCtrl: ModalController,
    private storageService: StorageService,
    private analytics: BhAnalyticsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  async dismiss() {
    this.analytics.clickEvent('about-page: dismiss', '');
    this.authService.userState.firstTimeViewed = true;
    this.authService.saveUserStateToStorage();
    this.modalCtrl.dismiss();
  }

}
