import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

/**
 * ID: bh-search-bar
 * Name: BH Search Bar
 * Description: Displays standardized search bar
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-10-11 - MW - v2: Added focus style and clear-focus
 * 2022-05-09 - MW - v3: Changed component name from bh-content-search to bh-search-bar
 */
@Component({
  selector: 'bh-search-bar',
  templateUrl: './bh-search-bar.component.html',
  styleUrls: ['./bh-search-bar.component.scss'],
})
export class BhSearchBarComponent implements OnChanges {
  @Input() placeholder = 'Search';
  @Input() layout: 'block' | 'compact' = 'block';
  @Input() showSearchButton = true;
  @Input() disabled = false;
  @Input() searchTerm = '';
  @Output() searchEvent = new EventEmitter();
  @ViewChild('searchInput', { static: true }) searchInput: any;
  isFocused = false;
  timer = null;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes:', changes);
    if ('searchTerm' in changes) {
      // console.log('searchTerm', this.searchTerm);
      this.searchEvent.emit(this.searchTerm);
    }
  }

  search() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.searchEvent.emit(this.searchTerm);
    }, 500);
  }

  clearSearch() {
    this.searchTerm = '';
    this.searchInput.nativeElement.focus();
    this.search();
  }

  onFocus(isFocused) {
    this.isFocused = isFocused;
  }

}
