import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription, firstValueFrom } from 'rxjs';
import { BhDataGridColumnDefinition } from 'src/app/models/_core/bh-data-grid-column-definition';
import { AllAppsData, VerlockerData } from 'src/app/models/locker-data';
import { VerlockerService } from 'src/app/services/verlocker/verlocker.service';
import { ManageVerlockerEditorPage } from '../manage-verlocker-editor/manage-verlocker-editor.page';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { AppData } from 'src/app/models/app-data';

@Component({
  selector: 'app-manage-verlocker',
  templateUrl: './manage-verlocker.page.html',
  styleUrls: ['./manage-verlocker.page.scss'],
})
export class ManageVerlockerPage implements OnInit {
  allAppsData: AllAppsData[] = [];
  verlockerData: VerlockerData[] = [];
  editMode: 'new' | 'edit' | 'all' | 'selected' = 'all';
  searchTerm = '';
  isFiltering = false;
  isLoading = true;
  titleVerlocker: string = '';
  subscriptions: Subscription[] = [];

  columnDefinitions: BhDataGridColumnDefinition[] = [
    {
      fieldName: 'appId',
      columnLabel: 'App ID',
      ionIcon: 'laptop-outline',
      ionIconColor: null,
      showIconArg: '',
      showIconOnly: false,
      alwaysShowIcon: true,
      showColumn: true,
    },
    {
      fieldName: 'appStoreName',
      columnLabel: 'App Store Name',
      showColumn: true,
      ionIcon: '',
      ionIconColor: null,
      showIconArg: null,
      showIconOnly: false,
      alwaysShowIcon: false,
    },
  ];
  selectedAppColumnDefinitions: BhDataGridColumnDefinition[] = [
    {fieldName: 'appId',columnLabel: 'App ID',ionIcon: '',ionIconColor: null,showIconArg: '',showIconOnly: false,alwaysShowIcon: true, showColumn: false},
    { fieldName: 'version', columnLabel: 'Version', showColumn: true, ionIcon: null, ionIconColor: null, showIconArg: null, showIconOnly: false, alwaysShowIcon: false },
    { fieldName: 'latestVersion', columnLabel: 'Active', showColumn: true, ionIcon: null, ionIconColor: null, showIconArg: null, showIconOnly: false, alwaysShowIcon: false },
    { fieldName: 'updateDescription', columnLabel: 'Update Description', showColumn: true, ionIcon: null, ionIconColor: null, showIconArg: null, showIconOnly: false, alwaysShowIcon: false },
    { fieldName: 'releaseDate', columnLabel: 'Release Date', showColumn: true, ionIcon: null, ionIconColor: null, showIconArg: null, showIconOnly: false, alwaysShowIcon: false },
    { fieldName: 'requestedDate', columnLabel: 'Requested Date', showColumn: true, ionIcon: null, ionIconColor: null, showIconArg: null, showIconOnly: false, alwaysShowIcon: false },
    { fieldName: 'expireDate', columnLabel: 'Expiration Date', showColumn: true, ionIcon: null, ionIconColor: null, showIconArg: null, showIconOnly: false, alwaysShowIcon: false },
    { fieldName: 'androidInstall', columnLabel: 'Android Install', showColumn: false, ionIcon: null, ionIconColor: null, showIconArg: null, showIconOnly: false, alwaysShowIcon: false },
    { fieldName: 'iosInstall', columnLabel: 'IOS Install', showColumn: false, ionIcon: null, ionIconColor: null, showIconArg: null, showIconOnly: false, alwaysShowIcon: false },
  ];

  constructor(
    private verlockerService: VerlockerService,
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.loadVerlockerData();
  }

  async addVerlocker() {
    this.analytics.clickEvent('Manage-Verlocker > add', 'adding');
    const modal = await this.modalCtrl.create({
      component: ManageVerlockerEditorPage,
      cssClass: 'edit-modal-2',
      componentProps: {
        editMode: 'new'
      }
    });

    modal.onDidDismiss().then(async (data) => {
      if (data && data.data) {
        if (data.data.isSaved) {
          //await this.loadData();
          //this.search(this.searchTerm);
        } else if (data.data.dismiss) {
          //this.modalCtrl.dismiss();
        }
      }
    });

    await modal.present();
  }

  selectLockerData(app: AllAppsData) {
    this.editMode = 'selected';
    this.titleVerlocker = app.appId;
    this.loadSpecificVerlockerData(app.appId);
  }

  async editLockerData(event) {
    this.analytics.clickEvent('Manage-Users > Edit', event.appId);
    const modal = await this.modalCtrl.create({
      component: ManageVerlockerEditorPage,
      cssClass: 'edit-modal-2',
      componentProps: {
        editMode: 'edit',
        selectedVerlockerData: event
      }
    });

    modal.onDidDismiss().then(async (data) => {
      if (data && data.data) {
        if (data.data.isSaved) {
          this.loadVerlockerData();
          //await this.loadData();
          //this.search(this.searchTerm);
        } else if (data.data.dismiss) {
          //this.modalCtrl.dismiss();
        }
      }
    });

    await modal.present();
  }


  async addLockerData() {
    this.analytics.clickEvent('Manage-Verlocker > Add', 'Add');
    const modal = await this.modalCtrl.create({
      component: ManageVerlockerEditorPage,
      cssClass: 'edit-modal-2',
      componentProps: {
        editMode: 'add',
        selectedVerlockerData: this.verlockerData[0]
      }
    });

    modal.onDidDismiss().then(async (data) => {
      if (data && data.data) {
        if (data.data.isSaved) {
          this.loadVerlockerData();
          //await this.loadSpecificVerlockerData(data.data.lockerData.appId);
          //await this.loadData();
          //this.search(this.searchTerm);
        } else if (data.data.dismiss) {
          //this.modalCtrl.dismiss();
        }
      }
    });

    await modal.present();
  }


  pickColor(app: AppData) {
    let color: string = '';
    switch (app.appTypeText.split(' ')[0].toLowerCase()) {
      case 'employee':
        color = 'primary';
        break;
      case 'patient':
        color = 'secondary';
        break;
      case 'partner':
        color = 'tertiary';
        break;
      default:
        color = 'warning'
        break;
    }
    return color;
  }

  async loadVerlockerData() {
    await firstValueFrom(this.verlockerService.loadAllVerlockerData()).then(
      (data) => {
        this.allAppsData = data.apps;
        this.isLoading = false;
        console.log('All Apps Data', this.allAppsData);
      }
    );
  }

  async loadSpecificVerlockerData(appId: string) {
    await firstValueFrom(
      this.verlockerService.getVerlockerDataForSingleApp(appId)
    ).then((data) => {
      this.verlockerData = data.verlocker;
      console.log('selected app Data', this.verlockerData);
    });
  }

  filter(ev) {
    if (ev) {
      this.isFiltering = true;
      const term = ev.toLowerCase().trim();
      // this.users = this.initUsers.filter(a =>
      //   a.fullName.toLowerCase().indexOf(term) > -1 ||
      //   a.userId.toLowerCase().indexOf(term) > -1 ||
      //   a.role.toLowerCase().indexOf(term) > -1
      // );
    } else {
      this.searchTerm = '';
      this.isFiltering = false;
      //this.users = this.initUsers;
    }
  }

  back() {
    this.editMode = 'all';
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
