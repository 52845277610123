import { BhAppVersionComponent } from './bh-app-version.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BhAppVersionComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    BhAppVersionComponent
  ]
})
export class BhAppVersionComponentModule { }
