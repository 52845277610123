<ion-content class="safe-area-margin-top">
  <div class="close-button-wrapper">
    <a href="javascript:void(0)" class="close" (click)="dismiss()">
      <ion-icon name="close-outline"></ion-icon>
    </a>
  </div>
  <div class="wide-viewport-container">
    <form [formGroup]="form1" *ngIf="currentView === 'form'">
      <h1 class="text-align-center">
        How likely are you to recommend {{ env?.appDescription }} to a
        colleague?
      </h1>
      <div
        class="nps-rating"
        [ngClass]="{ 'selected': selectedRating !== null }"
      >
        <div class="nps-rating-labels">
          <span class="nps-rating-label">Not likely</span>
          <span class="nps-rating-label text-align-right"
            >Extremely likely</span
          >
        </div>
        <div class="nps-rating-scale">
          <a
            href="javascript:void(0)"
            (click)="setRating(0)"
            [ngClass]="{ 'selected': selectedRating === 0 }"
            class="nps-rating-scale-number red"
            >0</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(1)"
            [ngClass]="{ 'selected': selectedRating === 1 }"
            class="nps-rating-scale-number red"
            >1</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(2)"
            [ngClass]="{ 'selected': selectedRating === 2 }"
            class="nps-rating-scale-number red"
            >2</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(3)"
            [ngClass]="{ 'selected': selectedRating === 3 }"
            class="nps-rating-scale-number red"
            >3</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(4)"
            [ngClass]="{ 'selected': selectedRating === 4 }"
            class="nps-rating-scale-number orange"
            >4</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(5)"
            [ngClass]="{ 'selected': selectedRating === 5 }"
            class="nps-rating-scale-number orange"
            >5</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(6)"
            [ngClass]="{ 'selected': selectedRating === 6 }"
            class="nps-rating-scale-number orange"
            >6</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(7)"
            [ngClass]="{ 'selected': selectedRating === 7 }"
            class="nps-rating-scale-number green"
            >7</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(8)"
            [ngClass]="{ 'selected': selectedRating === 8 }"
            class="nps-rating-scale-number green"
            >8</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(9)"
            [ngClass]="{ 'selected': selectedRating === 9 }"
            class="nps-rating-scale-number green"
            >9</a
          >
          <a
            href="javascript:void(0)"
            (click)="setRating(10)"
            [ngClass]="{ 'selected': selectedRating === 10 }"
            class="nps-rating-scale-number green"
            >10</a
          >
        </div>
        <div class="nps-rating-text" *ngIf="selectedRating !== null">
          <bh-input
            [formGroup]="form1"
            type="ion-textarea"
            formControlName="feedbackText"
            [placeholder]="placeholderText"
            [value]=""
            [selectOptions]="[]"
            [validationMessages]="validationMessages"
            [submitAttempted]="submitAttempted"
          ></bh-input>
        </div>
        <div class="nps-rating-submit" *ngIf="selectedRating !== null">
          <ion-button (click)="submit()">Submit</ion-button>
        </div>
      </div>
    </form>
    <bh-confirmation
      *ngIf="currentView === 'confirmation'"
      headingText="Thank you for your feedback!"
      detailText="We're always looking to find ways to make this app better."
      [centerButtons]="true"
      (continueEvent)="dismiss()"
      [showShareButton]="true"
    ></bh-confirmation>
  </div>
</ion-content>
