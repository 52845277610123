<ion-header class="modal-header mobile-only">
  <ion-toolbar>
    <ion-title> </ion-title>
    <ion-buttons slot="end">
      <ion-button shape="round" (click)="dismiss()" size="small"
        ><ion-icon name="close-outline"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="two-panel">
    <div class="information-panel desktop-only">
      <ng-container *ngIf="editMode === 'new'">
        <h1>New User</h1>
        <p>
          Please fill out the fields and click save grant download access to the
          application.
        </p>
      </ng-container>
      <ng-container *ngIf="editMode === 'edit'">
        <h1>{{modalTitle}}</h1>
        <p>
          Please enter 'ALL' for EN # and User ID to grant download access for
          everyone.
        </p>
      </ng-container>
    </div>
    <div class="form-panel">
      <div class="form-panel-inner">
        <a
          href="javascript:void(0)"
          (click)="dismiss()"
          class="close-button desktop-only"
        >
          <ion-icon name="close-outline"></ion-icon>
        </a>
        <form [formGroup]="form1">
          <div class="edit-mode">
            <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="appId"
              placeholder=""
              label="Enter App ID"
              position="stacked"
              [value]=""
              [readonly]="true"
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>
          </div>

          <h3>Access</h3>
          <div class="access-control-container">
            <div class="action-container">
              <ion-item lines="none">
                <ion-checkbox [(ngModel)]="isAllAccess" [ngModelOptions]="{standalone: true}" (ionChange)="accessChanged($event)" ></ion-checkbox>
                <ion-label>
                  All Access
                </ion-label>
              </ion-item>
              <!-- <ion-item lines="none">
                <ion-button (click)="addAccessRow(initialFormState)">Add New User Access</ion-button>
              </ion-item> -->
            </div>
          </div>

          <div class="access-form-row">
            <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="en"
              placeholder=""
              label="EN #"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>
            <ng-container
            *ngIf="form1.controls.en.value && editMode === 'new'"
          >
            <div class="check-status" *ngIf="checkStatus === 'checking'">
              <ion-spinner name="crescent" size="small"></ion-spinner>
              Checking for existing employee number
            </div>

            <div class="check-status success" *ngIf="checkStatus === 'new'">
              <ion-icon name="checkmark-circle-outline"></ion-icon>
              This employee can be added.
            </div>

            <div
              class="check-status warning"
              *ngIf="checkStatus === 'exists'"
            >
              <ion-icon name="alert-circle-outline"></ion-icon>
              <span>This employee's access already exists.</span>
            </div>
          </ng-container>
            <bh-input
              [formGroup]="form1"
              type="ion-text"
              formControlName="userId"
              placeholder=""
              label="User ID"
              position="stacked"
              [value]=""
              [validationMessages]="validationMessages"
              [submitAttempted]="submitAttempted"
            ></bh-input>
          </div>
          <!-- <ng-container *ngFor="let a of accessRow">
          </ng-container> -->

          <div class="delete-container" *ngIf="editMode === 'edit'">
            <ion-button
              class="delete-btn"
              (click)="triggerDelete()"
              [ngClass]="{'disableBtn': showDeleteMessage}"
            >
              Delete Access
            </ion-button>
            <p class="delete-message" *ngIf="showDeleteMessage">
              Click save below to remove the patient.
            </p>
          </div>
        </form>
        <div class="form-panel-footer desktop-only">
          <!-- <div class="form-panel-footer-spacer"></div> -->
          <div class="form-panel-footer-inner">
            <ion-button (click)="save()" [disabled]="!form1.valid || !formReady"
              >Save</ion-button
            >
            <ion-button fill="outline" (click)="dismiss()">Discard</ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="mobile-only">
  <ion-button (click)="save()" [disabled]="!form1.valid || !formReady">Save</ion-button>
</ion-footer>
