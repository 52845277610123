import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, ActivatedRoute } from '@angular/router';

/**
 * ID: bh-analytics-route-guard-service
 * Name: BH Analytics Route Guard Service
 * Description: Captures page hits via app-routing route guard.
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Injectable({
  providedIn: 'root'
})
export class BhAnalyticsRouteGuardService implements CanActivate {

  constructor(
    private bhAnalyticsService: BhAnalyticsService,
    ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    const totalSegments = (route.url) ? route.url.length : null;
    const lastSegment = (totalSegments) ? route.url[totalSegments - 1] : null;
    let entryName = lastSegment.toString();
    if (route.data.pageName) {
      entryName = route.data.pageName;
    }
    this.bhAnalyticsService.screenView(entryName, route.url.join('/'));
    return true;
  }

}